import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from "react-router-dom";
import pp from "./components/privacyPolicy";
import tos from "./components/termsOfService";

function NotFound() {
  return <h2>404 - Not Found</h2>;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy" element={        <>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n            *{\n                background: #36393f !important;\n            }\n        "
    }}
  />
  <div id="hosted">
    <div data-iframe-height="true">
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    [data-custom-class='body'], [data-custom-class='body'] * {\n            background: #36393f !important;\n          }\n  [data-custom-class='title'], [data-custom-class='title'] * {\n            font-family: Arial !important;\n  font-size: 26px !important;\n  color: #ffffff !important;\n          }\n  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n            font-family: Arial !important;\n  color: #ffffff !important;\n  font-size: 14px !important;\n          }\n  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n            font-family: Arial !important;\n  font-size: 19px !important;\n  color: #ffffff !important;\n          }\n  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n            font-family: Arial !important;\n  font-size: 17px !important;\n  color: #ffffff !important;\n          }\n  [data-custom-class='body_text'], [data-custom-class='body_text'] * {\n            color: #ffffff !important;\n  font-size: 14px !important;\n  font-family: Arial !important;\n          }\n  [data-custom-class='link'], [data-custom-class='link'] * {\n            color: #4a90e2 !important;\n  font-size: 14px !important;\n  font-family: Arial !important;\n  word-break: break-word !important;\n          }\n  "
        }}
      />
      <div data-custom-class="body">
        <div>
          <strong>
            <span style={{ fontSize: 26 }}>
              <span data-custom-class="title">PRIVACY NOTICE</span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="subtitle">
                  Last updated <bdt className="question">April 13, 2021</bdt>
                </span>
              </span>
            </strong>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                This privacy notice for{" "}
                <bdt className="question">
                  Oreki Bot
                  <bdt className="block-component" /> (doing business as{" "}
                  <bdt className="question">Oreki Bot LLC</bdt>)
                  <bdt className="statement-end-if-in-editor" />
                </bdt>{" "}
                (<bdt className="block-component" />"
                <bdt className="block-component" />
                <bdt className="question">
                  <strong>Oreki Bot LLC</strong>
                </bdt>
                <bdt className="else-block" />
                ," "<strong>we</strong>," "<strong>us</strong>," or "
                <strong>our</strong>"
                <bdt className="statement-end-if-in-editor" />
              </span>
              <span data-custom-class="body_text">
                ), describes how and why we might collect, store, use, and/or
                share (<bdt className="block-component" />"
                <strong>process</strong>"
                <bdt className="statement-end-if-in-editor" />) your information
                when you use our services (<bdt className="block-component" />"
                <strong>Services</strong>"
                <bdt className="statement-end-if-in-editor" />
                ), such as when you:
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Visit our website
                  <bdt className="block-component" /> at{" "}
                  <bdt className="question">
                    <a
                      href="https://oreki.theilker.com"
                      target="_blank"
                      data-custom-class="link"
                    >
                      https://oreki.theilker.com
                    </a>
                  </bdt>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor">
                              , or any website of ours that links to this
                              privacy notice
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </bdt>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Download and use
                  <bdt className="block-component" /> our mobile application
                  <bdt className="block-component" /> (
                  <bdt className="question">
                    Oreki Bot)
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="statement-end-if-in-editor">
                                      ,
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </bdt>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor">
                              <bdt className="block-component">
                                {" "}
                                or any other application of ours that links to
                                this privacy notice
                              </bdt>
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <strong>Questions or concerns?&nbsp;</strong>Reading this
                privacy notice will help you understand your privacy rights and
                choices. If you do not agree with our policies and practices,
                please do not use our Services. If you still have any questions
                or concerns, please contact us at{" "}
                <bdt className="question">contact@theilker.com</bdt>.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <strong>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
            </span>
          </strong>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our
                  table of contents below to find the section you are looking
                  for. You can also click&nbsp;
                </em>
              </strong>
            </span>
          </span>
          <a data-custom-class="link" href="#toc">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>here</em>
                </strong>
              </span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>&nbsp;to go directly to our table of contents.</em>
              </strong>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with{" "}
              <bdt className="block-component" />
              <bdt className="question">Oreki Bot LLC</bdt>
              <bdt className="else-block" /> and the Services, the choices you
              make, and the products and features you use. Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#personalinfo">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">&nbsp;to learn more.</span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>Do we process any sensitive personal information?</strong>{" "}
              <bdt className="block-component" />
              We do not process sensitive personal information.
              <bdt className="else-block" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>Do we receive any information from third parties?</strong>{" "}
              <bdt className="block-component" />
              We do not receive any information from third parties.
              <bdt className="else-block" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#infouse">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">&nbsp;to learn more.</span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                In what situations and with which{" "}
                <bdt className="block-component" />
                types of <bdt className="statement-end-if-in-editor" />
                parties do we share personal information?
              </strong>{" "}
              We may share information in specific situations and with specific{" "}
              <bdt className="block-component" />
              categories of <bdt className="statement-end-if-in-editor" />
              third parties. Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#whoshare">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              &nbsp;to learn more.
              <bdt className="block-component" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>How do we keep your information safe?</strong> We have{" "}
              <bdt className="block-component" />
              organizational
              <bdt className="statement-end-if-in-editor" /> and technical
              processes and procedures in place to protect your personal
              information. However, no electronic transmission over the internet
              or information storage technology can be guaranteed to be 100%
              secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other <bdt className="block-component" />
              unauthorized
              <bdt className="statement-end-if-in-editor" /> third parties will
              not be able to defeat our security and improperly collect, access,
              steal, or modify your information. Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              &nbsp;to learn more.
              <bdt className="statement-end-if-in-editor" />
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information.
              Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#privacyrights">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">&nbsp;to learn more.</span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by filling out our data subject request
              form available <bdt className="block-component" />
            </span>
          </span>
          <a
            data-custom-class="link"
            href="https://app.termly.io/notify/954ffc3e-6e85-4969-b8d7-b3be96c1ec45"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />, or by contacting us. We will
              consider and act upon any request in accordance with applicable
              data protection laws.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              Want to learn more about what <bdt className="block-component" />
              <bdt className="question">Oreki Bot LLC</bdt>
              <bdt className="else-block" /> does with any information we
              collect? Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#toc">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">here</span>
            </span>
          </a>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              &nbsp;to review the notice in full.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="toc" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                </strong>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infocollect">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                1. WHAT INFORMATION DO WE COLLECT?
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infouse">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                2. HOW DO WE PROCESS YOUR INFORMATION?
                <bdt className="block-component" />
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#legalbases">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                3.{" "}
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?
                  </span>
                </span>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a data-custom-class="link" href="#whoshare">
                4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </span>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#cookies">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#sociallogins">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </span>
                </span>
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#inforetain">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#infosafe">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </span>
            </a>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <a data-custom-class="link" href="#privacyrights">
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </a>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#DNT">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#caresidents">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <a data-custom-class="link" href="#policyupdates">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                12. DO WE MAKE UPDATES TO THIS NOTICE?
              </span>
            </a>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#contact">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#request">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </a>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="infocollect" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        1. WHAT INFORMATION DO WE COLLECT?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div id="personalinfo" style={{ lineHeight: "1.5" }}>
          <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15 }}>
              <strong>Personal information you disclose to us</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:</em>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>&nbsp;</em>
                        </strong>
                        <em>
                          We collect personal information that you provide to
                          us.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                We collect personal information that you voluntarily provide to
                us when you{" "}
                <span style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </span>
              </span>
              <span data-custom-class="body_text">
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>Personal Information Provided by You.</strong> The
                personal information that we collect depends on the context of
                your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">email addresses</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">usernames</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">discord information</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">names</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">internet protocol address</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">discord activity</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="question">discord messages</bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="forloop-component" />
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>Sensitive Information.</strong>{" "}
              <bdt className="block-component" />
              We do not process sensitive information.
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="else-block" />
            </span>
          </span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>Payment Data.</strong> We may collect data necessary to
                process your payment if you make purchases, such as your payment
                instrument number, and the security code associated with your
                payment instrument. All payment data is stored by
                <bdt className="forloop-component" />
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>{" "}
                <bdt className="question">Coinbase</bdt>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="forloop-component" />
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />{" "}
                                                        and{" "}
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="question">
                                                                Clover
                                                              </bdt>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        . You may find their
                                                        privacy notice link(s)
                                                        here:
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="forloop-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>{" "}
                                                        <bdt className="question">
                                                          <a
                                                            href="https://commerce.coinbase.com/legal/privacy-policy/"
                                                            target="_blank"
                                                            data-custom-class="link"
                                                          >
                                                            https://commerce.coinbase.com/legal/privacy-policy/
                                                          </a>
                                                        </bdt>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <bdt className="forloop-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />{" "}
                                                                        and{" "}
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="question">
                                                                                <a
                                                                                  href="https://www.clover.com/privacy-policy"
                                                                                  target="_blank"
                                                                                  data-custom-class="link"
                                                                                >
                                                                                  https://www.clover.com/privacy-policy
                                                                                </a>
                                                                              </bdt>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="forloop-component" />
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            .
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>Social Media Login Data.&nbsp;</strong>We may provide
                you with the option to register with us using your existing
                social media account details, like your Facebook, Twitter, or
                other social media account. If you choose to register in this
                way, we will collect the information described in the section
                called <bdt className="block-component" />"
                <bdt className="statement-end-if-in-editor" />
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <a data-custom-class="link" href="#sociallogins">
                          HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="block-component" />"
                <bdt className="statement-end-if-in-editor" /> below.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="statement-end-if-in-editor" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
          <bdt className="block-component">
            <bdt className="block-component" />
          </bdt>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15 }}>
              <strong>Information automatically collected</strong>
            </span>
          </span>
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:</em>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>&nbsp;</em>
                        </strong>
                        <em>
                          Some information — such as your Internet Protocol (IP)
                          address and/or browser and device characteristics — is
                          collected automatically when you visit our Services.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Like many businesses, we also collect information through
                cookies and similar technologies.{" "}
                <bdt className="block-component" />
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor">
                  <span data-custom-class="body_text" />
                </bdt>
              </span>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                The information we collect includes:
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Log and Usage Data.</em> Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the Services
                  <span style={{ fontSize: 15 }}>&nbsp;</span>(such as the
                  date/time stamps associated with your usage, pages and files
                  viewed, searches, and other actions you take such as which
                  features you use), device event information (such as system
                  activity, error reports (sometimes called{" "}
                  <bdt className="block-component" />
                  "crash dumps"
                  <bdt className="statement-end-if-in-editor" />
                  ), and hardware settings).
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Device Data.</em> We collect device data such as
                  information about your computer, phone, tablet, or other
                  device you use to access the Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Location Data.</em> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }} />
          </bdt>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component">
                <bdt className="forloop-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
              </bdt>
            </span>
          </bdt>
        </div>
        <ul>
          <li style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">
                            <em>Discord Data</em>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </em>
                  .{" "}
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="question">
                          Data provided by your Discord account. Examples
                          include the guild you're in, Discord email address,
                          username, messages, etc
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component">
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="statement-end-if-in-editor">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <bdt className="question">
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="forloop-component" />
                                  </span>
                                </span>
                              </span>
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </bdt>
                  <bdt className="statement-end-if-in-editor" />
                </bdt>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor">
                          <bdt className="block-component" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </div>
        <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div>
          <div style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <bdt className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </bdt>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </bdt>
                          </p>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </bdt>
                          </p>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                          </p>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                          </p>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>
                                        To request feedback.&nbsp;
                                      </strong>
                                      We may process your information when
                                      necessary to request feedback and to
                                      contact you about your use of our
                                      Services.
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }} />
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                  </span>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <ul>
                                        <li style={{ lineHeight: "1.5" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: 15 }}>
                                              <strong>
                                                To protect our Services.
                                              </strong>{" "}
                                              We may process your information as
                                              part of our efforts to keep our
                                              Services safe and secure,
                                              including fraud monitoring and
                                              prevention.
                                            </span>
                                          </span>
                                          <bdt className="statement-end-if-in-editor">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                            </div>
                                            <ul>
                                              <li style={{ lineHeight: "1.5" }}>
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <strong>
                                                      To identify usage trends.
                                                    </strong>{" "}
                                                    We may process information
                                                    about how you use our
                                                    Services to better
                                                    understand how they are
                                                    being used so we can improve
                                                    them.
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <strong>
                                                            To save or protect
                                                            an individual's
                                                            vital interest.
                                                          </strong>{" "}
                                                          We may process your
                                                          information when
                                                          necessary to save or
                                                          protect an
                                                          individual’s vital
                                                          interest, such as to
                                                          prevent harm.
                                                        </span>
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="legalbases"
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <strong>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="heading_1">
                                                          3. WHAT LEGAL BASES DO
                                                          WE RELY ON TO PROCESS
                                                          YOUR INFORMATION?
                                                        </span>
                                                      </span>
                                                    </strong>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <em>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            In Short:&nbsp;
                                                          </strong>
                                                          We only process your
                                                          personal information
                                                          when we believe it is
                                                          necessary and we have
                                                          a valid legal reason
                                                          (i.e.
                                                          <bdt className="block-component" />
                                                          ,
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          legal basis) to do so
                                                          under applicable law,
                                                          like with your
                                                          consent, to comply
                                                          with laws, to provide
                                                          you with services to
                                                          enter into or{" "}
                                                          <bdt className="block-component" />
                                                          fulfill
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          our contractual
                                                          obligations, to
                                                          protect your rights,
                                                          or to{" "}
                                                          <bdt className="block-component" />
                                                          fulfill
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          our legitimate
                                                          business interests.
                                                        </span>
                                                      </span>
                                                    </em>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <em>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              If you are located
                                                              in the EU or UK,
                                                              this section
                                                              applies to you.
                                                            </u>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </em>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        The General Data
                                                        Protection Regulation
                                                        (GDPR) and UK GDPR
                                                        require us to explain
                                                        the valid legal bases we
                                                        rely on in order to
                                                        process your personal
                                                        information. As such, we
                                                        may rely on the
                                                        following legal bases to
                                                        process your personal
                                                        information:
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Consent.&nbsp;
                                                          </strong>
                                                          We may process your
                                                          information if you
                                                          have given us
                                                          permission (i.e.
                                                          <bdt className="block-component" />
                                                          ,
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          consent) to use your
                                                          personal information
                                                          for a specific
                                                          purpose. You can
                                                          withdraw your consent
                                                          at any time.
                                                          Click&nbsp;
                                                        </span>
                                                      </span>
                                                      <a
                                                        data-custom-class="link"
                                                        href="#withdrawconsent"
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            here
                                                          </span>
                                                        </span>
                                                      </a>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          &nbsp;to learn more.
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <strong>
                                                              Legitimate
                                                              Interests.
                                                            </strong>{" "}
                                                            We may process your
                                                            information when we
                                                            believe it is
                                                            reasonably necessary
                                                            to achieve our
                                                            legitimate business
                                                            interests and those
                                                            interests do not
                                                            outweigh your
                                                            interests and
                                                            fundamental rights
                                                            and freedoms. For
                                                            example, we may
                                                            process your
                                                            personal information
                                                            for some of the
                                                            purposes described
                                                            in order to:
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul
                                                          style={{
                                                            marginLeft: 40
                                                          }}
                                                        >
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                                Analyze
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                how our Services
                                                                are used so we
                                                                can improve them
                                                                to engage and
                                                                retain users
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul
                                                            style={{
                                                              marginLeft: 40
                                                            }}
                                                          >
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  Diagnose
                                                                  problems
                                                                  and/or prevent
                                                                  fraudulent
                                                                  activities
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul
                                                            style={{
                                                              marginLeft: 40
                                                            }}
                                                          >
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  Understand how
                                                                  our users use
                                                                  our products
                                                                  and services
                                                                  so we can
                                                                  improve user
                                                                  experience
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    Legal
                                                                    Obligations.
                                                                  </strong>{" "}
                                                                  We may process
                                                                  your
                                                                  information
                                                                  where we
                                                                  believe it is
                                                                  necessary for
                                                                  compliance
                                                                  with our legal
                                                                  obligations,
                                                                  such as to
                                                                  cooperate with
                                                                  a law
                                                                  enforcement
                                                                  body or
                                                                  regulatory
                                                                  agency,
                                                                  exercise or
                                                                  defend our
                                                                  legal rights,
                                                                  or disclose
                                                                  your
                                                                  information as
                                                                  evidence in
                                                                  litigation in
                                                                  which we are
                                                                  involved.
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                  <br />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    Vital
                                                                    Interests.
                                                                  </strong>{" "}
                                                                  We may process
                                                                  your
                                                                  information
                                                                  where we
                                                                  believe it is
                                                                  necessary to
                                                                  protect your
                                                                  vital
                                                                  interests or
                                                                  the vital
                                                                  interests of a
                                                                  third party,
                                                                  such as
                                                                  situations
                                                                  involving
                                                                  potential
                                                                  threats to the
                                                                  safety of any
                                                                  person.
                                                                </span>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  <u>
                                                                    <em>
                                                                      If you are
                                                                      located in
                                                                      Canada,
                                                                      this
                                                                      section
                                                                      applies to
                                                                      you.
                                                                    </em>
                                                                  </u>
                                                                </strong>
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                We may process
                                                                your information
                                                                if you have
                                                                given us
                                                                specific
                                                                permission (i.e.
                                                                <bdt className="block-component" />
                                                                ,
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                express consent)
                                                                to use your
                                                                personal
                                                                information for
                                                                a specific
                                                                purpose, or in
                                                                situations where
                                                                your permission
                                                                can be inferred
                                                                (i.e.
                                                                <bdt className="block-component" />
                                                                ,
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                implied
                                                                consent). You
                                                                can withdraw
                                                                your consent at
                                                                any time.
                                                                Click&nbsp;
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#withdrawconsent"
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  here
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                &nbsp;to learn
                                                                more.
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                In some
                                                                exceptional
                                                                cases, we may be
                                                                legally
                                                                permitted under
                                                                applicable law
                                                                to process your
                                                                information
                                                                without your
                                                                consent,
                                                                including, for
                                                                example:
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  If collection
                                                                  is clearly in
                                                                  the interests
                                                                  of an
                                                                  individual and
                                                                  consent cannot
                                                                  be obtained in
                                                                  a timely way
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  For
                                                                  investigations
                                                                  and fraud
                                                                  detection and
                                                                  prevention
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  For business
                                                                  transactions
                                                                  provided
                                                                  certain
                                                                  conditions are
                                                                  met
                                                                </span>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  If it is
                                                                  contained in a
                                                                  witness
                                                                  statement and
                                                                  the collection
                                                                  is necessary
                                                                  to assess,
                                                                  process, or
                                                                  settle an
                                                                  insurance
                                                                  claim
                                                                </span>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  For
                                                                  identifying
                                                                  injured, ill,
                                                                  or deceased
                                                                  persons and
                                                                  communicating
                                                                  with next of
                                                                  kin
                                                                </span>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  If we have
                                                                  reasonable
                                                                  grounds to
                                                                  believe an
                                                                  individual has
                                                                  been, is, or
                                                                  may be victim
                                                                  of financial
                                                                  abuse
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  If it is
                                                                  reasonable to
                                                                  expect
                                                                  collection and
                                                                  use with
                                                                  consent would
                                                                  compromise the
                                                                  availability
                                                                  or the
                                                                  accuracy of
                                                                  the
                                                                  information
                                                                  and the
                                                                  collection is
                                                                  reasonable for
                                                                  purposes
                                                                  related to
                                                                  investigating
                                                                  a breach of an
                                                                  agreement or a
                                                                  contravention
                                                                  of the laws of
                                                                  Canada or a
                                                                  province
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  If disclosure
                                                                  is required to
                                                                  comply with a
                                                                  subpoena,
                                                                  warrant, court
                                                                  order, or
                                                                  rules of the
                                                                  court relating
                                                                  to the
                                                                  production of
                                                                  records
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If it was
                                                                  produced by an
                                                                  individual in
                                                                  the course of
                                                                  their
                                                                  employment,
                                                                  business, or
                                                                  profession and
                                                                  the collection
                                                                  is consistent
                                                                  with the
                                                                  purposes for
                                                                  which the
                                                                  information
                                                                  was produced
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If the
                                                                  collection is
                                                                  solely for
                                                                  journalistic,
                                                                  artistic, or
                                                                  literary
                                                                  purposes
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If the
                                                                  information is
                                                                  publicly
                                                                  available and
                                                                  is specified
                                                                  by the
                                                                  regulations
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span data-custom-class="body_text" />
                                                                </bdt>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="whoshare"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          4.
                                                                          WHEN
                                                                          AND
                                                                          WITH
                                                                          WHOM
                                                                          DO WE
                                                                          SHARE
                                                                          YOUR
                                                                          PERSONAL
                                                                          INFORMATION?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We may
                                                                    share
                                                                    information
                                                                    in specific
                                                                    situations
                                                                    described in
                                                                    this section
                                                                    and/or with
                                                                    the
                                                                    following{" "}
                                                                    <bdt className="block-component" />
                                                                    categories
                                                                    of{" "}
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    third
                                                                    parties.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Vendors,
                                                                  Consultants,
                                                                  and Other
                                                                  Third-Party
                                                                  Service
                                                                  Providers.
                                                                </strong>{" "}
                                                                We may share
                                                                your data with
                                                                third-party
                                                                vendors, service
                                                                providers,
                                                                contractors, or
                                                                agents (
                                                                <bdt className="block-component" />
                                                                "
                                                                <strong>
                                                                  third parties
                                                                </strong>
                                                                "
                                                                <bdt className="statement-end-if-in-editor" />
                                                                ) who perform
                                                                services for us
                                                                or on our behalf
                                                                and require
                                                                access to such
                                                                information to
                                                                do that work.{" "}
                                                                <bdt className="block-component" />
                                                                We have
                                                                contracts in
                                                                place with our
                                                                third parties,
                                                                which are
                                                                designed to help
                                                                safeguard your
                                                                personal
                                                                information.
                                                                This means that
                                                                they cannot do
                                                                anything with
                                                                your personal
                                                                information
                                                                unless we have
                                                                instructed them
                                                                to do it. They
                                                                will also not
                                                                share your
                                                                personal
                                                                information with
                                                                any{" "}
                                                                <bdt className="block-component" />
                                                                organization
                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                apart from us.
                                                                They also commit
                                                                to protect the
                                                                data they hold
                                                                on our behalf
                                                                and to retain it
                                                                for the period
                                                                we instruct.{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                                The{" "}
                                                                <bdt className="block-component" />
                                                                categories of{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                                third parties we
                                                                may share
                                                                personal
                                                                information with
                                                                are as follows:
                                                              </span>
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                      Data
                                                                      Analytics
                                                                      Services
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="forloop-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                      Payment
                                                                      Processors
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="forloop-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                      User
                                                                      Account
                                                                      Registration
                                                                      &amp;
                                                                      Authentication
                                                                      Services
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="forloop-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                      Website
                                                                      Hosting
                                                                      Service
                                                                      Providers
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="forloop-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="question">
                                                                      Performance
                                                                      Monitoring
                                                                      Tools
                                                                    </bdt>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="forloop-component">
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <bdt className="block-component" />
                                                            <span data-custom-class="body_text" />
                                                            <span data-custom-class="body_text" />
                                                            <span data-custom-class="body_text" />
                                                            <span data-custom-class="body_text" />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                We{" "}
                                                                <bdt className="block-component" />
                                                                also{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                                may need to
                                                                share your
                                                                personal
                                                                information in
                                                                the following
                                                                situations:
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    Business
                                                                    Transfers.
                                                                  </strong>{" "}
                                                                  We may share
                                                                  or transfer
                                                                  your
                                                                  information in
                                                                  connection
                                                                  with, or
                                                                  during
                                                                  negotiations
                                                                  of, any
                                                                  merger, sale
                                                                  of company
                                                                  assets,
                                                                  financing, or
                                                                  acquisition of
                                                                  all or a
                                                                  portion of our
                                                                  business to
                                                                  another
                                                                  company.
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span data-custom-class="body_text" />
                                                                </bdt>
                                                              </span>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5"
                                                                  }}
                                                                >
                                                                  <bdt className="block-component">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </bdt>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                    </bdt>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            Offer
                                                                            Wall.&nbsp;
                                                                          </strong>
                                                                          Our
                                                                          application(s)
                                                                          may
                                                                          display
                                                                          a
                                                                          third-party
                                                                          hosted{" "}
                                                                          <bdt className="block-component" />
                                                                          "offer
                                                                          wall."
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          Such
                                                                          an
                                                                          offer
                                                                          wall
                                                                          allows
                                                                          third-party
                                                                          advertisers
                                                                          to
                                                                          offer
                                                                          virtual
                                                                          currency,
                                                                          gifts,
                                                                          or
                                                                          other
                                                                          items
                                                                          to
                                                                          users
                                                                          in
                                                                          return
                                                                          for
                                                                          the
                                                                          acceptance
                                                                          and
                                                                          completion
                                                                          of an
                                                                          advertisement
                                                                          offer.
                                                                          Such
                                                                          an
                                                                          offer
                                                                          wall
                                                                          may
                                                                          appear
                                                                          in our
                                                                          application(s)
                                                                          and be
                                                                          displayed
                                                                          to you
                                                                          based
                                                                          on
                                                                          certain
                                                                          data,
                                                                          such
                                                                          as
                                                                          your
                                                                          geographic
                                                                          area
                                                                          or
                                                                          demographic
                                                                          information.
                                                                          When
                                                                          you
                                                                          click
                                                                          on an
                                                                          offer
                                                                          wall,
                                                                          you
                                                                          will
                                                                          be
                                                                          brought
                                                                          to an
                                                                          external
                                                                          website
                                                                          belonging
                                                                          to
                                                                          other
                                                                          persons
                                                                          and
                                                                          will
                                                                          leave
                                                                          our
                                                                          application(s).
                                                                          A
                                                                          unique
                                                                          identifier,
                                                                          such
                                                                          as
                                                                          your
                                                                          user
                                                                          ID,
                                                                          will
                                                                          be
                                                                          shared
                                                                          with
                                                                          the
                                                                          offer
                                                                          wall
                                                                          provider
                                                                          in
                                                                          order
                                                                          to
                                                                          prevent
                                                                          fraud
                                                                          and
                                                                          properly
                                                                          credit
                                                                          your
                                                                          account
                                                                          with
                                                                          the
                                                                          relevant
                                                                          reward.
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text" />
                                                                        </span>
                                                                      </bdt>
                                                                    </li>
                                                                  </ul>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                    </bdt>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <bdt className="block-component">
                                                                                <span data-custom-class="heading_1" />
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="cookies"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  5.
                                                                                  DO
                                                                                  WE
                                                                                  USE
                                                                                  COOKIES
                                                                                  AND
                                                                                  OTHER
                                                                                  TRACKING
                                                                                  TECHNOLOGIES?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;We
                                                                            may
                                                                            use
                                                                            cookies
                                                                            and
                                                                            other
                                                                            tracking
                                                                            technologies
                                                                            to
                                                                            collect
                                                                            and
                                                                            store
                                                                            your
                                                                            information.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          use
                                                                          cookies
                                                                          and
                                                                          similar
                                                                          tracking
                                                                          technologies
                                                                          (like
                                                                          web
                                                                          beacons
                                                                          and
                                                                          pixels)
                                                                          to
                                                                          access
                                                                          or
                                                                          store
                                                                          information.
                                                                          Specific
                                                                          information
                                                                          about
                                                                          how we
                                                                          use
                                                                          such
                                                                          technologies
                                                                          and
                                                                          how
                                                                          you
                                                                          can
                                                                          refuse
                                                                          certain
                                                                          cookies
                                                                          is set
                                                                          out in
                                                                          our
                                                                          Cookie
                                                                          Notice
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              .
                                                                            </span>
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="sociallogins"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  6.
                                                                                  HOW
                                                                                  DO
                                                                                  WE
                                                                                  HANDLE
                                                                                  YOUR
                                                                                  SOCIAL
                                                                                  LOGINS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            If
                                                                            you
                                                                            choose
                                                                            to
                                                                            register
                                                                            or
                                                                            log
                                                                            in
                                                                            to
                                                                            our
                                                                            Services
                                                                            using
                                                                            a
                                                                            social
                                                                            media
                                                                            account,
                                                                            we
                                                                            may
                                                                            have
                                                                            access
                                                                            to
                                                                            certain
                                                                            information
                                                                            about
                                                                            you.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Our
                                                                          Services
                                                                          offer
                                                                          you
                                                                          the
                                                                          ability
                                                                          to
                                                                          register
                                                                          and
                                                                          log in
                                                                          using
                                                                          your
                                                                          third-party
                                                                          social
                                                                          media
                                                                          account
                                                                          details
                                                                          (like
                                                                          your
                                                                          Facebook
                                                                          or
                                                                          Twitter
                                                                          logins).
                                                                          Where
                                                                          you
                                                                          choose
                                                                          to do
                                                                          this,
                                                                          we
                                                                          will
                                                                          receive
                                                                          certain
                                                                          profile
                                                                          information
                                                                          about
                                                                          you
                                                                          from
                                                                          your
                                                                          social
                                                                          media
                                                                          provider.
                                                                          The
                                                                          profile
                                                                          information
                                                                          we
                                                                          receive
                                                                          may
                                                                          vary
                                                                          depending
                                                                          on the
                                                                          social
                                                                          media
                                                                          provider
                                                                          concerned,
                                                                          but
                                                                          will
                                                                          often
                                                                          include
                                                                          your
                                                                          name,
                                                                          email
                                                                          address,
                                                                          friends
                                                                          list,
                                                                          and
                                                                          profile
                                                                          picture,
                                                                          as
                                                                          well
                                                                          as
                                                                          other
                                                                          information
                                                                          you
                                                                          choose
                                                                          to
                                                                          make
                                                                          public
                                                                          on
                                                                          such a
                                                                          social
                                                                          media
                                                                          platform.{" "}
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          use
                                                                          the
                                                                          information
                                                                          we
                                                                          receive
                                                                          only
                                                                          for
                                                                          the
                                                                          purposes
                                                                          that
                                                                          are
                                                                          described
                                                                          in
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          or
                                                                          that
                                                                          are
                                                                          otherwise
                                                                          made
                                                                          clear
                                                                          to you
                                                                          on the
                                                                          relevant
                                                                          Services.
                                                                          Please
                                                                          note
                                                                          that
                                                                          we do
                                                                          not
                                                                          control,
                                                                          and
                                                                          are
                                                                          not
                                                                          responsible
                                                                          for,
                                                                          other
                                                                          uses
                                                                          of
                                                                          your
                                                                          personal
                                                                          information
                                                                          by
                                                                          your
                                                                          third-party
                                                                          social
                                                                          media
                                                                          provider.
                                                                          We
                                                                          recommend
                                                                          that
                                                                          you
                                                                          review
                                                                          their
                                                                          privacy
                                                                          notice
                                                                          to
                                                                          understand
                                                                          how
                                                                          they
                                                                          collect,
                                                                          use,
                                                                          and
                                                                          share
                                                                          your
                                                                          personal
                                                                          information,
                                                                          and
                                                                          how
                                                                          you
                                                                          can
                                                                          set
                                                                          your
                                                                          privacy
                                                                          preferences
                                                                          on
                                                                          their
                                                                          sites
                                                                          and
                                                                          apps.
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                          <bdt className="block-component">
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                          </bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="inforetain"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  7.
                                                                                  HOW
                                                                                  LONG
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            keep
                                                                            your
                                                                            information
                                                                            for
                                                                            as
                                                                            long
                                                                            as
                                                                            necessary
                                                                            to{" "}
                                                                            <bdt className="block-component" />
                                                                            fulfill
                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                            the
                                                                            purposes
                                                                            outlined
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            unless
                                                                            otherwise
                                                                            required
                                                                            by
                                                                            law.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          only
                                                                          keep
                                                                          your
                                                                          personal
                                                                          information
                                                                          for as
                                                                          long
                                                                          as it
                                                                          is
                                                                          necessary
                                                                          for
                                                                          the
                                                                          purposes
                                                                          set
                                                                          out in
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          unless
                                                                          a
                                                                          longer
                                                                          retention
                                                                          period
                                                                          is
                                                                          required
                                                                          or
                                                                          permitted
                                                                          by law
                                                                          (such
                                                                          as
                                                                          tax,
                                                                          accounting,
                                                                          or
                                                                          other
                                                                          legal
                                                                          requirements).
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          When
                                                                          we
                                                                          have
                                                                          no
                                                                          ongoing
                                                                          legitimate
                                                                          business
                                                                          need
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          we
                                                                          will
                                                                          either
                                                                          delete
                                                                          or{" "}
                                                                          <bdt className="block-component" />
                                                                          anonymize
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          such
                                                                          information,
                                                                          or, if
                                                                          this
                                                                          is not
                                                                          possible
                                                                          (for
                                                                          example,
                                                                          because
                                                                          your
                                                                          personal
                                                                          information
                                                                          has
                                                                          been
                                                                          stored
                                                                          in
                                                                          backup
                                                                          archives),
                                                                          then
                                                                          we
                                                                          will
                                                                          securely
                                                                          store
                                                                          your
                                                                          personal
                                                                          information
                                                                          and
                                                                          isolate
                                                                          it
                                                                          from
                                                                          any
                                                                          further
                                                                          processing
                                                                          until
                                                                          deletion
                                                                          is
                                                                          possible.
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="infosafe"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  8.
                                                                                  HOW
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION
                                                                                  SAFE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            aim
                                                                            to
                                                                            protect
                                                                            your
                                                                            personal
                                                                            information
                                                                            through
                                                                            a
                                                                            system
                                                                            of{" "}
                                                                            <bdt className="block-component" />
                                                                            organizational
                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                            and
                                                                            technical
                                                                            security
                                                                            measures.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          have
                                                                          implemented
                                                                          appropriate
                                                                          and
                                                                          reasonable
                                                                          technical
                                                                          and{" "}
                                                                          <bdt className="block-component" />
                                                                          organizational
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          security
                                                                          measures
                                                                          designed
                                                                          to
                                                                          protect
                                                                          the
                                                                          security
                                                                          of any
                                                                          personal
                                                                          information
                                                                          we
                                                                          process.
                                                                          However,
                                                                          despite
                                                                          our
                                                                          safeguards
                                                                          and
                                                                          efforts
                                                                          to
                                                                          secure
                                                                          your
                                                                          information,
                                                                          no
                                                                          electronic
                                                                          transmission
                                                                          over
                                                                          the
                                                                          Internet
                                                                          or
                                                                          information
                                                                          storage
                                                                          technology
                                                                          can be
                                                                          guaranteed
                                                                          to be
                                                                          100%
                                                                          secure,
                                                                          so we
                                                                          cannot
                                                                          promise
                                                                          or
                                                                          guarantee
                                                                          that
                                                                          hackers,
                                                                          cybercriminals,
                                                                          or
                                                                          other{" "}
                                                                          <bdt className="block-component" />
                                                                          unauthorized
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          third
                                                                          parties
                                                                          will
                                                                          not be
                                                                          able
                                                                          to
                                                                          defeat
                                                                          our
                                                                          security
                                                                          and
                                                                          improperly
                                                                          collect,
                                                                          access,
                                                                          steal,
                                                                          or
                                                                          modify
                                                                          your
                                                                          information.
                                                                          Although
                                                                          we
                                                                          will
                                                                          do our
                                                                          best
                                                                          to
                                                                          protect
                                                                          your
                                                                          personal
                                                                          information,
                                                                          transmission
                                                                          of
                                                                          personal
                                                                          information
                                                                          to and
                                                                          from
                                                                          our
                                                                          Services
                                                                          is at
                                                                          your
                                                                          own
                                                                          risk.
                                                                          You
                                                                          should
                                                                          only
                                                                          access
                                                                          the
                                                                          Services
                                                                          within
                                                                          a
                                                                          secure
                                                                          environment.
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="privacyrights"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  9.
                                                                                  WHAT
                                                                                  ARE
                                                                                  YOUR
                                                                                  PRIVACY
                                                                                  RIGHTS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <bdt className="block-component" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            In
                                                                            some
                                                                            regions,
                                                                            such
                                                                            as{" "}
                                                                            <bdt className="block-component" />
                                                                            the
                                                                            European
                                                                            Economic
                                                                            Area
                                                                            (EEA),
                                                                            United
                                                                            Kingdom
                                                                            (UK),
                                                                            and
                                                                            Canada
                                                                            <bdt className="block-component" />
                                                                            ,
                                                                            you
                                                                            have
                                                                            rights
                                                                            that
                                                                            allow
                                                                            you
                                                                            greater
                                                                            access
                                                                            to
                                                                            and
                                                                            control
                                                                            over
                                                                            your
                                                                            personal
                                                                            information.
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                              &nbsp;
                                                                            </span>
                                                                            You
                                                                            may
                                                                            review,
                                                                            change,
                                                                            or
                                                                            terminate
                                                                            your
                                                                            account
                                                                            at
                                                                            any
                                                                            time.
                                                                          </em>
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          In
                                                                          some
                                                                          regions
                                                                          (like{" "}
                                                                          <bdt className="block-component" />
                                                                          the
                                                                          EEA,
                                                                          UK,
                                                                          and
                                                                          Canada
                                                                          <bdt className="block-component" />
                                                                          ), you
                                                                          have
                                                                          certain
                                                                          rights
                                                                          under
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          These
                                                                          may
                                                                          include
                                                                          the
                                                                          right
                                                                          (i) to
                                                                          request
                                                                          access
                                                                          and
                                                                          obtain
                                                                          a copy
                                                                          of
                                                                          your
                                                                          personal
                                                                          information,
                                                                          (ii)
                                                                          to
                                                                          request
                                                                          rectification
                                                                          or
                                                                          erasure;
                                                                          (iii)
                                                                          to
                                                                          restrict
                                                                          the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information;
                                                                          and
                                                                          (iv)
                                                                          if
                                                                          applicable,
                                                                          to
                                                                          data
                                                                          portability.
                                                                          In
                                                                          certain
                                                                          circumstances,
                                                                          you
                                                                          may
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          object
                                                                          to the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information.
                                                                          You
                                                                          can
                                                                          make
                                                                          such a
                                                                          request
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                          <bdt className="block-component" />
                                                                          "
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="#contact"
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </a>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          "
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          below.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          consider
                                                                          and
                                                                          act
                                                                          upon
                                                                          any
                                                                          request
                                                                          in
                                                                          accordance
                                                                          with
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      &nbsp;
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          located
                                                                          in the
                                                                          EEA or
                                                                          UK and
                                                                          you
                                                                          believe
                                                                          we are
                                                                          unlawfully
                                                                          processing
                                                                          your
                                                                          personal
                                                                          information,
                                                                          you
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          complain
                                                                          to
                                                                          your
                                                                          local
                                                                          data
                                                                          protection
                                                                          supervisory
                                                                          authority.
                                                                          You
                                                                          can
                                                                          find
                                                                          their
                                                                          contact
                                                                          details
                                                                          here:{" "}
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(48, 48, 241)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <a
                                                                                      data-custom-class="link"
                                                                                      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                      rel="noopener noreferrer"
                                                                                      target="_blank"
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                                      </span>
                                                                                    </a>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          located
                                                                          in
                                                                          Switzerland,
                                                                          the
                                                                          contact
                                                                          details
                                                                          for
                                                                          the
                                                                          data
                                                                          protection
                                                                          authorities
                                                                          are
                                                                          available
                                                                          here:{" "}
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(48, 48, 241)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <a
                                                                                        data-custom-class="link"
                                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                      >
                                                                                        https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                                      </a>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="withdrawconsent"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <u>
                                                                              Withdrawing
                                                                              your
                                                                              consent:
                                                                            </u>
                                                                          </strong>{" "}
                                                                          If we
                                                                          are
                                                                          relying
                                                                          on
                                                                          your
                                                                          consent
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          <bdt className="block-component" />{" "}
                                                                          which
                                                                          may be
                                                                          express
                                                                          and/or
                                                                          implied
                                                                          consent
                                                                          depending
                                                                          on the
                                                                          applicable
                                                                          law,
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          you
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time.
                                                                          You
                                                                          can
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                          <bdt className="block-component" />
                                                                          "
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="#contact"
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </a>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          "
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          below
                                                                          <bdt className="block-component" />
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        However,
                                                                        please
                                                                        note
                                                                        that
                                                                        this
                                                                        will not
                                                                        affect
                                                                        the
                                                                        lawfulness
                                                                        of the
                                                                        processing
                                                                        before
                                                                        its
                                                                        withdrawal
                                                                        nor,
                                                                        <bdt className="block-component" />{" "}
                                                                        when
                                                                        applicable
                                                                        law
                                                                        allows,
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        will it
                                                                        affect
                                                                        the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information
                                                                        conducted
                                                                        in
                                                                        reliance
                                                                        on
                                                                        lawful
                                                                        processing
                                                                        grounds
                                                                        other
                                                                        than
                                                                        consent.
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                    <bdt className="block-component">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                    </bdt>
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <bdt className="block-component" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <u>
                                                                              Cookies
                                                                              and
                                                                              similar
                                                                              technologies:
                                                                            </u>
                                                                          </strong>{" "}
                                                                          Most
                                                                          Web
                                                                          browsers
                                                                          are
                                                                          set to
                                                                          accept
                                                                          cookies
                                                                          by
                                                                          default.
                                                                          If you
                                                                          prefer,
                                                                          you
                                                                          can
                                                                          usually
                                                                          choose
                                                                          to set
                                                                          your
                                                                          browser
                                                                          to
                                                                          remove
                                                                          cookies
                                                                          and to
                                                                          reject
                                                                          cookies.
                                                                          If you
                                                                          choose
                                                                          to
                                                                          remove
                                                                          cookies
                                                                          or
                                                                          reject
                                                                          cookies,
                                                                          this
                                                                          could
                                                                          affect
                                                                          certain
                                                                          features
                                                                          or
                                                                          services
                                                                          of our
                                                                          Services.
                                                                          To opt
                                                                          out of
                                                                          interest-based
                                                                          advertising
                                                                          by
                                                                          advertisers
                                                                          on our
                                                                          Services
                                                                          visit{" "}
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(48, 48, 241)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <a
                                                                                data-custom-class="link"
                                                                                href="http://www.aboutads.info/choices/"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  http://www.aboutads.info/choices/
                                                                                </span>
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                          .{" "}
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt className="block-component">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        If you
                                                                        have
                                                                        questions
                                                                        or
                                                                        comments
                                                                        about
                                                                        your
                                                                        privacy
                                                                        rights,
                                                                        you may
                                                                        email us
                                                                        at{" "}
                                                                        <bdt className="question">
                                                                          contact@theilker.com
                                                                        </bdt>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="DNT"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  10.
                                                                                  CONTROLS
                                                                                  FOR
                                                                                  DO-NOT-TRACK
                                                                                  FEATURES
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Most
                                                                          web
                                                                          browsers
                                                                          and
                                                                          some
                                                                          mobile
                                                                          operating
                                                                          systems
                                                                          and
                                                                          mobile
                                                                          applications
                                                                          include
                                                                          a
                                                                          Do-Not-Track
                                                                          (
                                                                          <bdt className="block-component" />
                                                                          "DNT"
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                          )
                                                                          feature
                                                                          or
                                                                          setting
                                                                          you
                                                                          can
                                                                          activate
                                                                          to
                                                                          signal
                                                                          your
                                                                          privacy
                                                                          preference
                                                                          not to
                                                                          have
                                                                          data
                                                                          about
                                                                          your
                                                                          online
                                                                          browsing
                                                                          activities
                                                                          monitored
                                                                          and
                                                                          collected.
                                                                          At
                                                                          this
                                                                          stage
                                                                          no
                                                                          uniform
                                                                          technology
                                                                          standard
                                                                          for{" "}
                                                                          <bdt className="block-component" />
                                                                          recognizing
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          and
                                                                          implementing
                                                                          DNT
                                                                          signals
                                                                          has
                                                                          been{" "}
                                                                          <bdt className="block-component" />
                                                                          finalized
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                          . As
                                                                          such,
                                                                          we do
                                                                          not
                                                                          currently
                                                                          respond
                                                                          to DNT
                                                                          browser
                                                                          signals
                                                                          or any
                                                                          other
                                                                          mechanism
                                                                          that
                                                                          automatically
                                                                          communicates
                                                                          your
                                                                          choice
                                                                          not to
                                                                          be
                                                                          tracked
                                                                          online.
                                                                          If a
                                                                          standard
                                                                          for
                                                                          online
                                                                          tracking
                                                                          is
                                                                          adopted
                                                                          that
                                                                          we
                                                                          must
                                                                          follow
                                                                          in the
                                                                          future,
                                                                          we
                                                                          will
                                                                          inform
                                                                          you
                                                                          about
                                                                          that
                                                                          practice
                                                                          in a
                                                                          revised
                                                                          version
                                                                          of
                                                                          this
                                                                          privacy
                                                                          notice.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="caresidents"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(127, 127, 127)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              id="control"
                                                                              style={{
                                                                                color:
                                                                                  "rgb(0, 0, 0)"
                                                                              }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  11.
                                                                                  DO
                                                                                  CALIFORNIA
                                                                                  RESIDENTS
                                                                                  HAVE
                                                                                  SPECIFIC
                                                                                  PRIVACY
                                                                                  RIGHTS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            Yes,
                                                                            if
                                                                            you
                                                                            are
                                                                            a
                                                                            resident
                                                                            of
                                                                            California,
                                                                            you
                                                                            are
                                                                            granted
                                                                            specific
                                                                            rights
                                                                            regarding
                                                                            access
                                                                            to
                                                                            your
                                                                            personal
                                                                            information.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          California
                                                                          Civil
                                                                          Code
                                                                          Section
                                                                          1798.83,
                                                                          also
                                                                          known
                                                                          as the{" "}
                                                                          <bdt className="block-component" />
                                                                          "Shine
                                                                          The
                                                                          Light"
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          law,
                                                                          permits
                                                                          our
                                                                          users
                                                                          who
                                                                          are
                                                                          California
                                                                          residents
                                                                          to
                                                                          request
                                                                          and
                                                                          obtain
                                                                          from
                                                                          us,
                                                                          once a
                                                                          year
                                                                          and
                                                                          free
                                                                          of
                                                                          charge,
                                                                          information
                                                                          about
                                                                          categories
                                                                          of
                                                                          personal
                                                                          information
                                                                          (if
                                                                          any)
                                                                          we
                                                                          disclosed
                                                                          to
                                                                          third
                                                                          parties
                                                                          for
                                                                          direct
                                                                          marketing
                                                                          purposes
                                                                          and
                                                                          the
                                                                          names
                                                                          and
                                                                          addresses
                                                                          of all
                                                                          third
                                                                          parties
                                                                          with
                                                                          which
                                                                          we
                                                                          shared
                                                                          personal
                                                                          information
                                                                          in the
                                                                          immediately
                                                                          preceding
                                                                          calendar
                                                                          year.
                                                                          If you
                                                                          are a
                                                                          California
                                                                          resident
                                                                          and
                                                                          would
                                                                          like
                                                                          to
                                                                          make
                                                                          such a
                                                                          request,
                                                                          please
                                                                          submit
                                                                          your
                                                                          request
                                                                          in
                                                                          writing
                                                                          to us
                                                                          using
                                                                          the
                                                                          contact
                                                                          information
                                                                          provided
                                                                          below.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          under
                                                                          18
                                                                          years
                                                                          of
                                                                          age,
                                                                          reside
                                                                          in
                                                                          California,
                                                                          and
                                                                          have a
                                                                          registered
                                                                          account
                                                                          with
                                                                          Services,
                                                                          you
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          request
                                                                          removal
                                                                          of
                                                                          unwanted
                                                                          data
                                                                          that
                                                                          you
                                                                          publicly
                                                                          post
                                                                          on the
                                                                          Services.
                                                                          To
                                                                          request
                                                                          removal
                                                                          of
                                                                          such
                                                                          data,
                                                                          please
                                                                          contact
                                                                          us
                                                                          using
                                                                          the
                                                                          contact
                                                                          information
                                                                          provided
                                                                          below
                                                                          and
                                                                          include
                                                                          the
                                                                          email
                                                                          address
                                                                          associated
                                                                          with
                                                                          your
                                                                          account
                                                                          and a
                                                                          statement
                                                                          that
                                                                          you
                                                                          reside
                                                                          in
                                                                          California.
                                                                          We
                                                                          will
                                                                          make
                                                                          sure
                                                                          the
                                                                          data
                                                                          is not
                                                                          publicly
                                                                          displayed
                                                                          on the
                                                                          Services,
                                                                          but
                                                                          please
                                                                          be
                                                                          aware
                                                                          that
                                                                          the
                                                                          data
                                                                          may
                                                                          not be
                                                                          completely
                                                                          or
                                                                          comprehensively
                                                                          removed
                                                                          from
                                                                          all
                                                                          our
                                                                          systems
                                                                          (e.g.
                                                                          <bdt className="block-component" />
                                                                          ,
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          backups,
                                                                          etc.).
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      data-custom-class="heading_2"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <strong>
                                                                          CCPA
                                                                          Privacy
                                                                          Notice
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div>
                                                                    <div>
                                                                      <br />
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(127, 127, 127)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  The
                                                                                  California
                                                                                  Code
                                                                                  of
                                                                                  Regulations
                                                                                  defines
                                                                                  a{" "}
                                                                                  <bdt className="block-component" />
                                                                                  "resident"
                                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                                  as:
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                      marginLeft: 20
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          (1)
                                                                          every
                                                                          individual
                                                                          who is
                                                                          in the
                                                                          State
                                                                          of
                                                                          California
                                                                          for
                                                                          other
                                                                          than a
                                                                          temporary
                                                                          or
                                                                          transitory
                                                                          purpose
                                                                          and
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                      marginLeft: 20
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          (2)
                                                                          every
                                                                          individual
                                                                          who is
                                                                          domiciled
                                                                          in the
                                                                          State
                                                                          of
                                                                          California
                                                                          who is
                                                                          outside
                                                                          the
                                                                          State
                                                                          of
                                                                          California
                                                                          for a
                                                                          temporary
                                                                          or
                                                                          transitory
                                                                          purpose
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          All
                                                                          other
                                                                          individuals
                                                                          are
                                                                          defined
                                                                          as{" "}
                                                                          <bdt className="block-component" />
                                                                          "non-residents."
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If
                                                                          this
                                                                          definition
                                                                          of{" "}
                                                                          <bdt className="block-component" />
                                                                          "resident"
                                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                                          applies
                                                                          to
                                                                          you,
                                                                          we
                                                                          must
                                                                          adhere
                                                                          to
                                                                          certain
                                                                          rights
                                                                          and
                                                                          obligations
                                                                          regarding
                                                                          your
                                                                          personal
                                                                          information.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            What
                                                                            categories
                                                                            of
                                                                            personal
                                                                            information
                                                                            do
                                                                            we
                                                                            collect?
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          have
                                                                          collected
                                                                          the
                                                                          following
                                                                          categories
                                                                          of
                                                                          personal
                                                                          information
                                                                          in the
                                                                          past
                                                                          twelve
                                                                          (12)
                                                                          months:
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <table
                                                                    style={{
                                                                      width:
                                                                        "100%"
                                                                    }}
                                                                  >
                                                                    <tbody>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <strong>
                                                                                  Category
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <strong>
                                                                                  Examples
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            textAlign:
                                                                              "center"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <strong>
                                                                                  Collected
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  A.
                                                                                  Identifiers
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Contact
                                                                                  details,
                                                                                  such
                                                                                  as
                                                                                  real
                                                                                  name,
                                                                                  alias,
                                                                                  postal
                                                                                  address,
                                                                                  telephone
                                                                                  or
                                                                                  mobile
                                                                                  contact
                                                                                  number,
                                                                                  unique
                                                                                  personal
                                                                                  identifier,
                                                                                  online
                                                                                  identifier,
                                                                                  Internet
                                                                                  Protocol
                                                                                  address,
                                                                                  email
                                                                                  address,
                                                                                  and
                                                                                  account
                                                                                  name
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            verticalAlign:
                                                                              "middle",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              YES
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                              <bdt className="forloop-component" />
                                                                              <bdt className="block-component" />
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  B.
                                                                                  Personal
                                                                                  information
                                                                                  categories
                                                                                  listed
                                                                                  in
                                                                                  the
                                                                                  California
                                                                                  Customer
                                                                                  Records
                                                                                  statute
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Name,
                                                                                  contact
                                                                                  information,
                                                                                  education,
                                                                                  employment,
                                                                                  employment
                                                                                  history,
                                                                                  and
                                                                                  financial
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="forloop-component" />
                                                                                  <bdt className="block-component" />
                                                                                  <bdt className="block-component" />
                                                                                  NO
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  C.
                                                                                  Protected
                                                                                  classification
                                                                                  characteristics
                                                                                  under
                                                                                  California
                                                                                  or
                                                                                  federal
                                                                                  law
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Gender
                                                                                  and
                                                                                  date
                                                                                  of
                                                                                  birth
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  D.
                                                                                  Commercial
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Transaction
                                                                                  information,
                                                                                  purchase
                                                                                  history,
                                                                                  financial
                                                                                  details,
                                                                                  and
                                                                                  payment
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              YES
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                              <bdt className="forloop-component" />
                                                                              <bdt className="block-component" />
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  E.
                                                                                  Biometric
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Fingerprints
                                                                                  and
                                                                                  voiceprints
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  F.
                                                                                  Internet
                                                                                  or
                                                                                  other
                                                                                  similar
                                                                                  network
                                                                                  activity
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Browsing
                                                                                  history,
                                                                                  search
                                                                                  history,
                                                                                  online{" "}
                                                                                  <bdt className="block-component" />
                                                                                  behavior
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                  ,
                                                                                  interest
                                                                                  data,
                                                                                  and
                                                                                  interactions
                                                                                  with
                                                                                  our
                                                                                  and
                                                                                  other
                                                                                  websites,
                                                                                  applications,
                                                                                  systems,
                                                                                  and
                                                                                  advertisements
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  G.
                                                                                  Geolocation
                                                                                  data
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Device
                                                                                  location
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  H.
                                                                                  Audio,
                                                                                  electronic,
                                                                                  visual,
                                                                                  thermal,
                                                                                  olfactory,
                                                                                  or
                                                                                  similar
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Images
                                                                                  and
                                                                                  audio,
                                                                                  video
                                                                                  or
                                                                                  call
                                                                                  recordings
                                                                                  created
                                                                                  in
                                                                                  connection
                                                                                  with
                                                                                  our
                                                                                  business
                                                                                  activities
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "33.8274%",
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  I.
                                                                                  Professional
                                                                                  or
                                                                                  employment-related
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "51.4385%",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Business
                                                                                  contact
                                                                                  details
                                                                                  in
                                                                                  order
                                                                                  to
                                                                                  provide
                                                                                  you
                                                                                  our
                                                                                  Services
                                                                                  at
                                                                                  a
                                                                                  business
                                                                                  level
                                                                                  or
                                                                                  job
                                                                                  title,
                                                                                  work
                                                                                  history,
                                                                                  and
                                                                                  professional
                                                                                  qualifications
                                                                                  if
                                                                                  you
                                                                                  apply
                                                                                  for
                                                                                  a
                                                                                  job
                                                                                  with
                                                                                  us
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            width:
                                                                              "14.9084%",
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            width:
                                                                              "33.8274%"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  J.
                                                                                  Education
                                                                                  Information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            width:
                                                                              "51.4385%"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Student
                                                                                  records
                                                                                  and
                                                                                  directory
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            width:
                                                                              "14.9084%"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            borderWidth: 1,
                                                                            borderColor:
                                                                              "black",
                                                                            borderStyle:
                                                                              "solid",
                                                                            width:
                                                                              "33.8274%"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  K.
                                                                                  Inferences
                                                                                  drawn
                                                                                  from
                                                                                  other
                                                                                  personal
                                                                                  information
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            borderBottom:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            width:
                                                                              "51.4385%"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Inferences
                                                                                  drawn
                                                                                  from
                                                                                  any
                                                                                  of
                                                                                  the
                                                                                  collected
                                                                                  personal
                                                                                  information
                                                                                  listed
                                                                                  above
                                                                                  to
                                                                                  create
                                                                                  a
                                                                                  profile
                                                                                  or
                                                                                  summary
                                                                                  about,
                                                                                  for
                                                                                  example,
                                                                                  an
                                                                                  individual’s
                                                                                  preferences
                                                                                  and
                                                                                  characteristics
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            textAlign:
                                                                              "center",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderBottom:
                                                                              "1px solid black",
                                                                            borderTop:
                                                                              "1px solid black",
                                                                            width:
                                                                              "14.9084%"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              NO
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            borderLeft:
                                                                              "1px solid black",
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderBottom:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            L.
                                                                            Sensitive
                                                                            Personal
                                                                            Information
                                                                          </span>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderBottom:
                                                                              "1px solid black",
                                                                            lineHeight:
                                                                              "1.5"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span data-custom-class="body_text" />
                                                                          </bdt>
                                                                        </td>
                                                                        <td
                                                                          style={{
                                                                            borderRight:
                                                                              "1px solid black",
                                                                            borderBottom:
                                                                              "1px solid black"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            data-empty="true"
                                                                            style={{
                                                                              textAlign:
                                                                                "center"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            data-custom-class="body_text"
                                                                            data-empty="true"
                                                                            style={{
                                                                              textAlign:
                                                                                "center",
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <bdt className="block-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                            NO
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span data-custom-class="body_text" />
                                                                            </bdt>
                                                                          </div>
                                                                          <div
                                                                            data-empty="true"
                                                                            style={{
                                                                              textAlign:
                                                                                "center"
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </bdt>
                                                                    <span data-custom-class="body_text">
                                                                      We will
                                                                      use and
                                                                      retain the
                                                                      collected
                                                                      personal
                                                                      information
                                                                      as needed
                                                                      to provide
                                                                      the
                                                                      Services
                                                                      or for:
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        Category
                                                                        A -{" "}
                                                                        <bdt className="question">
                                                                          As
                                                                          long
                                                                          as the
                                                                          user
                                                                          has an
                                                                          account
                                                                          with
                                                                          us
                                                                        </bdt>
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </li>
                                                                  </ul>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </div>
                                                                      <ul>
                                                                        <li
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            Category
                                                                            D -{" "}
                                                                            <bdt className="question">
                                                                              As
                                                                              long
                                                                              as
                                                                              the
                                                                              user
                                                                              has
                                                                              an
                                                                              account
                                                                              with
                                                                              us
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                        </li>
                                                                      </ul>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                            <div
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1.5"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5"
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5"
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                      </span>
                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                        <span data-custom-class="body_text" />
                                                                                      </bdt>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              We
                                                                                              may
                                                                                              also
                                                                                              collect
                                                                                              other
                                                                                              personal
                                                                                              information
                                                                                              outside
                                                                                              of
                                                                                              these
                                                                                              categories
                                                                                              through
                                                                                              instances
                                                                                              where
                                                                                              you
                                                                                              interact
                                                                                              with
                                                                                              us
                                                                                              in
                                                                                              person,
                                                                                              online,
                                                                                              or
                                                                                              by
                                                                                              phone
                                                                                              or
                                                                                              mail
                                                                                              in
                                                                                              the
                                                                                              context
                                                                                              of:
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight:
                                                                                              "1.5"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                Receiving
                                                                                                help
                                                                                                through
                                                                                                our
                                                                                                customer
                                                                                                support
                                                                                                channels;
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight:
                                                                                              "1.5"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                Participation
                                                                                                in
                                                                                                customer
                                                                                                surveys
                                                                                                or
                                                                                                contests;
                                                                                                and
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <ul>
                                                                                        <li
                                                                                          style={{
                                                                                            lineHeight:
                                                                                              "1.5"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                Facilitation
                                                                                                in
                                                                                                the
                                                                                                delivery
                                                                                                of
                                                                                                our
                                                                                                Services
                                                                                                and
                                                                                                to
                                                                                                respond
                                                                                                to
                                                                                                your
                                                                                                inquiries.
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </li>
                                                                                      </ul>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <strong>
                                                                                                How
                                                                                                do
                                                                                                we
                                                                                                use
                                                                                                and
                                                                                                share
                                                                                                your
                                                                                                personal
                                                                                                information?
                                                                                              </strong>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <bdt className="block-component" />
                                                                                                  <bdt className="block-component" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <span data-custom-class="body_text">
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    More
                                                                                                    information
                                                                                                    about
                                                                                                    our
                                                                                                    data
                                                                                                    collection
                                                                                                    and
                                                                                                    sharing
                                                                                                    practices
                                                                                                    can
                                                                                                    be
                                                                                                    found
                                                                                                    in
                                                                                                    this
                                                                                                    privacy
                                                                                                    notice
                                                                                                    <bdt className="block-component" />
                                                                                                    .
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="block-component" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              You
                                                                                              may
                                                                                              contact
                                                                                              us{" "}
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <bdt className="block-component" />
                                                                                                </span>
                                                                                              </span>
                                                                                              by
                                                                                              email
                                                                                              at&nbsp;
                                                                                            </span>
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt className="question">
                                                                                                  contact@theilker.com
                                                                                                </bdt>
                                                                                                ,{" "}
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                <bdt className="block-component" />
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                                <bdt className="block-component">
                                                                                                  <span data-custom-class="body_text" />
                                                                                                </bdt>
                                                                                                <bdt className="question">
                                                                                                  Message
                                                                                                  Scuttler#001
                                                                                                  on
                                                                                                  Discord
                                                                                                </bdt>
                                                                                                ,{" "}
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <span data-custom-class="body_text">
                                                                                          or
                                                                                          by
                                                                                          referring
                                                                                          to
                                                                                          the
                                                                                          contact
                                                                                          details
                                                                                          at
                                                                                          the
                                                                                          bottom
                                                                                          of
                                                                                          this
                                                                                          document.
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              If
                                                                                              you
                                                                                              are
                                                                                              using
                                                                                              an{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              agent
                                                                                              to
                                                                                              exercise
                                                                                              your
                                                                                              right
                                                                                              to
                                                                                              opt
                                                                                              out
                                                                                              we
                                                                                              may
                                                                                              deny
                                                                                              a
                                                                                              request
                                                                                              if
                                                                                              the{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              agent
                                                                                              does
                                                                                              not
                                                                                              submit
                                                                                              proof
                                                                                              that
                                                                                              they
                                                                                              have
                                                                                              been
                                                                                              validly{" "}
                                                                                              <bdt className="block-component" />
                                                                                              authorized
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              to
                                                                                              act
                                                                                              on
                                                                                              your
                                                                                              behalf.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <strong>
                                                                                                Will
                                                                                                your
                                                                                                information
                                                                                                be
                                                                                                shared
                                                                                                with
                                                                                                anyone
                                                                                                else?
                                                                                              </strong>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              We
                                                                                              may
                                                                                              disclose
                                                                                              your
                                                                                              personal
                                                                                              information
                                                                                              with
                                                                                              our
                                                                                              service
                                                                                              providers
                                                                                              pursuant
                                                                                              to
                                                                                              a
                                                                                              written
                                                                                              contract
                                                                                              between
                                                                                              us
                                                                                              and
                                                                                              each
                                                                                              service
                                                                                              provider.
                                                                                              Each
                                                                                              service
                                                                                              provider
                                                                                              is
                                                                                              a
                                                                                              for-profit
                                                                                              entity
                                                                                              that
                                                                                              processes
                                                                                              the
                                                                                              information
                                                                                              on
                                                                                              our
                                                                                              behalf,
                                                                                              following
                                                                                              the
                                                                                              same
                                                                                              strict
                                                                                              privacy
                                                                                              protection
                                                                                              obligations
                                                                                              mandated
                                                                                              by
                                                                                              the
                                                                                              CCPA.
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              We
                                                                                              may
                                                                                              use
                                                                                              your
                                                                                              personal
                                                                                              information
                                                                                              for
                                                                                              our
                                                                                              own
                                                                                              business
                                                                                              purposes,
                                                                                              such
                                                                                              as
                                                                                              for
                                                                                              undertaking
                                                                                              internal
                                                                                              research
                                                                                              for
                                                                                              technological
                                                                                              development
                                                                                              and
                                                                                              demonstration.
                                                                                              This
                                                                                              is
                                                                                              not
                                                                                              considered
                                                                                              to
                                                                                              be{" "}
                                                                                              <bdt className="block-component" />
                                                                                              "selling"
                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                              of
                                                                                              your
                                                                                              personal
                                                                                              information.
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <bdt className="block-component" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </div>
                                                                                      <div
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5"
                                                                                        }}
                                                                                      >
                                                                                        <bdt className="block-component">
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text" />
                                                                                          </span>
                                                                                        </bdt>
                                                                                        <span data-custom-class="body_text">
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="question">
                                                                                              Oreki
                                                                                              Bot
                                                                                            </bdt>{" "}
                                                                                            has
                                                                                            not
                                                                                            sold
                                                                                            or
                                                                                            shared
                                                                                            any
                                                                                            personal
                                                                                            information
                                                                                            to
                                                                                            third
                                                                                            parties
                                                                                            for
                                                                                            a
                                                                                            business
                                                                                            or
                                                                                            commercial
                                                                                            purpose
                                                                                            in
                                                                                            the
                                                                                            preceding
                                                                                            twelve
                                                                                            (12)
                                                                                            months.&nbsp;
                                                                                          </span>
                                                                                        </span>
                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text" />
                                                                                          </span>
                                                                                        </bdt>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt className="question">
                                                                                                  Oreki
                                                                                                  Bot
                                                                                                </bdt>
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                &nbsp;has
                                                                                                disclosed
                                                                                                the
                                                                                                following
                                                                                                categories
                                                                                                of
                                                                                                personal
                                                                                                information
                                                                                                to
                                                                                                third
                                                                                                parties
                                                                                                for
                                                                                                a
                                                                                                business
                                                                                                or
                                                                                                commercial
                                                                                                purpose
                                                                                                in
                                                                                                the
                                                                                                preceding
                                                                                                twelve
                                                                                                (12)
                                                                                                months:
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <bdt className="forloop-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            lineHeight:
                                                                                              "1.5"
                                                                                          }}
                                                                                        >
                                                                                          <bdt className="forloop-component">
                                                                                            <span data-custom-class="body_text" />
                                                                                          </bdt>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            lineHeight:
                                                                                              "1.5"
                                                                                          }}
                                                                                        >
                                                                                          <bdt className="forloop-component">
                                                                                            <span data-custom-class="body_text" />
                                                                                          </bdt>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <bdt className="forloop-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                        <p
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                        </p>
                                                                                        <div
                                                                                          style={{
                                                                                            lineHeight:
                                                                                              "1.5"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </span>
                                                                                          <p
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </p>
                                                                                          <p
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component" />
                                                                                          </p>
                                                                                          <p
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="forloop-component">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              />
                                                                                            </bdt>
                                                                                          </p>
                                                                                          <p
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <bdt className="forloop-component" />
                                                                                            </span>
                                                                                          </p>
                                                                                          <div>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <bdt className="forloop-component" />
                                                                                            </span>
                                                                                            <div>
                                                                                              <bdt className="forloop-component">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                />
                                                                                              </bdt>
                                                                                              <div>
                                                                                                <bdt className="forloop-component">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  />
                                                                                                </bdt>
                                                                                                <div>
                                                                                                  <bdt className="forloop-component">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    />
                                                                                                  </bdt>
                                                                                                  <div>
                                                                                                    <bdt className="block-component">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      />
                                                                                                    </bdt>
                                                                                                    <div>
                                                                                                      <bdt className="block-component" />
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              The
                                                                                                              categories
                                                                                                              of
                                                                                                              third
                                                                                                              parties
                                                                                                              to
                                                                                                              whom
                                                                                                              we
                                                                                                              disclosed
                                                                                                              personal
                                                                                                              information
                                                                                                              for
                                                                                                              a
                                                                                                              business
                                                                                                              or
                                                                                                              commercial
                                                                                                              purpose
                                                                                                              can
                                                                                                              be
                                                                                                              found
                                                                                                              under{" "}
                                                                                                              <bdt className="block-component" />
                                                                                                              "
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <a
                                                                                                                    data-custom-class="link"
                                                                                                                    href="#whoshare"
                                                                                                                  >
                                                                                                                    WHEN
                                                                                                                    AND
                                                                                                                    WITH
                                                                                                                    WHOM
                                                                                                                    DO
                                                                                                                    WE
                                                                                                                    SHARE
                                                                                                                    YOUR
                                                                                                                    PERSONAL
                                                                                                                    INFORMATION?
                                                                                                                  </a>
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <bdt className="block-component" />
                                                                                                                                  "
                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                              .
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span
                                                                                                                                    style={{
                                                                                                                                      fontSize: 15
                                                                                                                                    }}
                                                                                                                                  >
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        color:
                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          fontSize: 15
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            color:
                                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              fontSize: 15
                                                                                                                                            }}
                                                                                                                                          >
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                color:
                                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  fontSize: 15
                                                                                                                                                }}
                                                                                                                                              >
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    color:
                                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                                      <span
                                                                                                                                                        style={{
                                                                                                                                                          fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                      >
                                                                                                                                                        <span
                                                                                                                                                          style={{
                                                                                                                                                            color:
                                                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                                                          }}
                                                                                                                                                        >
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span
                                                                                                                                                                      style={{
                                                                                                                                                                        color:
                                                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                                                      }}
                                                                                                                                                                    >
                                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                      </span>
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </span>
                                                                                                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(0, 0, 0)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="block-component" />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <strong>
                                                                                                                Your
                                                                                                                rights
                                                                                                                with
                                                                                                                respect
                                                                                                                to
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                              </strong>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <u>
                                                                                                                Right
                                                                                                                to
                                                                                                                request
                                                                                                                deletion
                                                                                                                of
                                                                                                                the
                                                                                                                data
                                                                                                                —
                                                                                                                Request
                                                                                                                to
                                                                                                                delete
                                                                                                              </u>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              You
                                                                                                              can
                                                                                                              ask
                                                                                                              for
                                                                                                              the
                                                                                                              deletion
                                                                                                              of
                                                                                                              your
                                                                                                              personal
                                                                                                              information.
                                                                                                              If
                                                                                                              you
                                                                                                              ask
                                                                                                              us
                                                                                                              to
                                                                                                              delete
                                                                                                              your
                                                                                                              personal
                                                                                                              information,
                                                                                                              we
                                                                                                              will
                                                                                                              respect
                                                                                                              your
                                                                                                              request
                                                                                                              and
                                                                                                              delete
                                                                                                              your
                                                                                                              personal
                                                                                                              information,
                                                                                                              subject
                                                                                                              to
                                                                                                              certain
                                                                                                              exceptions
                                                                                                              provided
                                                                                                              by
                                                                                                              law,
                                                                                                              such
                                                                                                              as
                                                                                                              (but
                                                                                                              not
                                                                                                              limited
                                                                                                              to)
                                                                                                              the
                                                                                                              exercise
                                                                                                              by
                                                                                                              another
                                                                                                              consumer
                                                                                                              of
                                                                                                              his
                                                                                                              or
                                                                                                              her
                                                                                                              right
                                                                                                              to
                                                                                                              free
                                                                                                              speech,
                                                                                                              our
                                                                                                              compliance
                                                                                                              requirements
                                                                                                              resulting
                                                                                                              from
                                                                                                              a
                                                                                                              legal
                                                                                                              obligation,
                                                                                                              or
                                                                                                              any
                                                                                                              processing
                                                                                                              that
                                                                                                              may
                                                                                                              be
                                                                                                              required
                                                                                                              to
                                                                                                              protect
                                                                                                              against
                                                                                                              illegal
                                                                                                              activities.
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <u>
                                                                                                                Right
                                                                                                                to
                                                                                                                be
                                                                                                                informed
                                                                                                                —
                                                                                                                Request
                                                                                                                to
                                                                                                                know
                                                                                                              </u>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              Depending
                                                                                                              on
                                                                                                              the
                                                                                                              circumstances,
                                                                                                              you
                                                                                                              have
                                                                                                              a
                                                                                                              right
                                                                                                              to
                                                                                                              know:
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                whether
                                                                                                                we
                                                                                                                collect
                                                                                                                and
                                                                                                                use
                                                                                                                your
                                                                                                                personal
                                                                                                                information;
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                the
                                                                                                                categories
                                                                                                                of
                                                                                                                personal
                                                                                                                information
                                                                                                                that
                                                                                                                we
                                                                                                                collect;
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                the
                                                                                                                purposes
                                                                                                                for
                                                                                                                which
                                                                                                                the
                                                                                                                collected
                                                                                                                personal
                                                                                                                information
                                                                                                                is
                                                                                                                used;
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                whether
                                                                                                                we
                                                                                                                sell
                                                                                                                or
                                                                                                                share
                                                                                                                personal
                                                                                                                information
                                                                                                                to
                                                                                                                third
                                                                                                                parties;
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                the
                                                                                                                categories
                                                                                                                of
                                                                                                                personal
                                                                                                                information
                                                                                                                that
                                                                                                                we
                                                                                                                sold,
                                                                                                                shared,
                                                                                                                or
                                                                                                                disclosed
                                                                                                                for
                                                                                                                a
                                                                                                                business
                                                                                                                purpose;
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                the
                                                                                                                categories
                                                                                                                of
                                                                                                                third
                                                                                                                parties
                                                                                                                to
                                                                                                                whom
                                                                                                                the
                                                                                                                personal
                                                                                                                information
                                                                                                                was
                                                                                                                sold,
                                                                                                                shared,
                                                                                                                or
                                                                                                                disclosed
                                                                                                                for
                                                                                                                a
                                                                                                                business
                                                                                                                purpose;
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                the
                                                                                                                business
                                                                                                                or
                                                                                                                commercial
                                                                                                                purpose
                                                                                                                for
                                                                                                                collecting,
                                                                                                                selling,
                                                                                                                or
                                                                                                                sharing
                                                                                                                personal
                                                                                                                information;
                                                                                                                and
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <bdt className="block-component">
                                                                                                          <span data-custom-class="body_text" />
                                                                                                        </bdt>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            the
                                                                                                            specific
                                                                                                            pieces
                                                                                                            of
                                                                                                            personal
                                                                                                            information
                                                                                                            we
                                                                                                            collected
                                                                                                            about
                                                                                                            you.
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              In
                                                                                                              accordance
                                                                                                              with
                                                                                                              applicable
                                                                                                              law,
                                                                                                              we
                                                                                                              are
                                                                                                              not
                                                                                                              obligated
                                                                                                              to
                                                                                                              provide
                                                                                                              or
                                                                                                              delete
                                                                                                              consumer
                                                                                                              information
                                                                                                              that
                                                                                                              is
                                                                                                              de-identified
                                                                                                              in
                                                                                                              response
                                                                                                              to
                                                                                                              a
                                                                                                              consumer
                                                                                                              request
                                                                                                              or
                                                                                                              to
                                                                                                              re-identify
                                                                                                              individual
                                                                                                              data
                                                                                                              to
                                                                                                              verify
                                                                                                              a
                                                                                                              consumer
                                                                                                              request.
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <u>
                                                                                                                Right
                                                                                                                to
                                                                                                                Non-Discrimination
                                                                                                                for
                                                                                                                the
                                                                                                                Exercise
                                                                                                                of
                                                                                                                a
                                                                                                                Consumer’s
                                                                                                                Privacy
                                                                                                                Rights
                                                                                                              </u>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              We
                                                                                                              will
                                                                                                              not
                                                                                                              discriminate
                                                                                                              against
                                                                                                              you
                                                                                                              if
                                                                                                              you
                                                                                                              exercise
                                                                                                              your
                                                                                                              privacy
                                                                                                              rights.
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <u>
                                                                                                          <span data-custom-class="body_text">
                                                                                                            Right
                                                                                                            to
                                                                                                            Limit
                                                                                                            Use
                                                                                                            and
                                                                                                            Disclosure
                                                                                                            of
                                                                                                            Sensitive
                                                                                                            Personal
                                                                                                            Information
                                                                                                          </span>
                                                                                                        </u>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <bdt className="block-component">
                                                                                                          <span data-custom-class="body_text" />
                                                                                                        </bdt>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          We
                                                                                                          do
                                                                                                          not
                                                                                                          process
                                                                                                          consumer's
                                                                                                          sensitive
                                                                                                          personal
                                                                                                          information.
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                          <span data-custom-class="body_text" />
                                                                                                        </bdt>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <u>
                                                                                                                Verification
                                                                                                                process
                                                                                                              </u>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              Upon
                                                                                                              receiving
                                                                                                              your
                                                                                                              request,
                                                                                                              we
                                                                                                              will
                                                                                                              need
                                                                                                              to
                                                                                                              verify
                                                                                                              your
                                                                                                              identity
                                                                                                              to
                                                                                                              determine
                                                                                                              you
                                                                                                              are
                                                                                                              the
                                                                                                              same
                                                                                                              person
                                                                                                              about
                                                                                                              whom
                                                                                                              we
                                                                                                              have
                                                                                                              the
                                                                                                              information
                                                                                                              in
                                                                                                              our
                                                                                                              system.
                                                                                                              These
                                                                                                              verification
                                                                                                              efforts
                                                                                                              require
                                                                                                              us
                                                                                                              to
                                                                                                              ask
                                                                                                              you
                                                                                                              to
                                                                                                              provide
                                                                                                              information
                                                                                                              so
                                                                                                              that
                                                                                                              we
                                                                                                              can
                                                                                                              match
                                                                                                              it
                                                                                                              with
                                                                                                              information
                                                                                                              you
                                                                                                              have
                                                                                                              previously
                                                                                                              provided
                                                                                                              us.
                                                                                                              For
                                                                                                              instance,
                                                                                                              depending
                                                                                                              on
                                                                                                              the
                                                                                                              type
                                                                                                              of
                                                                                                              request
                                                                                                              you
                                                                                                              submit,
                                                                                                              we
                                                                                                              may
                                                                                                              ask
                                                                                                              you
                                                                                                              to
                                                                                                              provide
                                                                                                              certain
                                                                                                              information
                                                                                                              so
                                                                                                              that
                                                                                                              we
                                                                                                              can
                                                                                                              match
                                                                                                              the
                                                                                                              information
                                                                                                              you
                                                                                                              provide
                                                                                                              with
                                                                                                              the
                                                                                                              information
                                                                                                              we
                                                                                                              already
                                                                                                              have
                                                                                                              on
                                                                                                              file,
                                                                                                              or
                                                                                                              we
                                                                                                              may
                                                                                                              contact
                                                                                                              you
                                                                                                              through
                                                                                                              a
                                                                                                              communication
                                                                                                              method
                                                                                                              (e.g.
                                                                                                              <bdt className="block-component" />
                                                                                                              ,
                                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                              phone
                                                                                                              or
                                                                                                              email)
                                                                                                              that
                                                                                                              you
                                                                                                              have
                                                                                                              previously
                                                                                                              provided
                                                                                                              to
                                                                                                              us.
                                                                                                              We
                                                                                                              may
                                                                                                              also
                                                                                                              use
                                                                                                              other
                                                                                                              verification
                                                                                                              methods
                                                                                                              as
                                                                                                              the
                                                                                                              circumstances
                                                                                                              dictate.
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              We
                                                                                                              will
                                                                                                              only
                                                                                                              use
                                                                                                              personal
                                                                                                              information
                                                                                                              provided
                                                                                                              in
                                                                                                              your
                                                                                                              request
                                                                                                              to
                                                                                                              verify
                                                                                                              your
                                                                                                              identity
                                                                                                              or
                                                                                                              authority
                                                                                                              to
                                                                                                              make
                                                                                                              the
                                                                                                              request.
                                                                                                              To
                                                                                                              the
                                                                                                              extent
                                                                                                              possible,
                                                                                                              we
                                                                                                              will
                                                                                                              avoid
                                                                                                              requesting
                                                                                                              additional
                                                                                                              information
                                                                                                              from
                                                                                                              you
                                                                                                              for
                                                                                                              the
                                                                                                              purposes
                                                                                                              of
                                                                                                              verification.
                                                                                                              However,
                                                                                                              if
                                                                                                              we
                                                                                                              cannot
                                                                                                              verify
                                                                                                              your
                                                                                                              identity
                                                                                                              from
                                                                                                              the
                                                                                                              information
                                                                                                              already
                                                                                                              maintained
                                                                                                              by
                                                                                                              us,
                                                                                                              we
                                                                                                              may
                                                                                                              request
                                                                                                              that
                                                                                                              you
                                                                                                              provide
                                                                                                              additional
                                                                                                              information
                                                                                                              for
                                                                                                              the
                                                                                                              purposes
                                                                                                              of
                                                                                                              verifying
                                                                                                              your
                                                                                                              identity
                                                                                                              and
                                                                                                              for
                                                                                                              security
                                                                                                              or
                                                                                                              fraud-prevention
                                                                                                              purposes.
                                                                                                              We
                                                                                                              will
                                                                                                              delete
                                                                                                              such
                                                                                                              additionally
                                                                                                              provided
                                                                                                              information
                                                                                                              as
                                                                                                              soon
                                                                                                              as
                                                                                                              we
                                                                                                              finish
                                                                                                              verifying
                                                                                                              you.
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <u>
                                                                                                                Other
                                                                                                                privacy
                                                                                                                rights
                                                                                                              </u>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                You
                                                                                                                may
                                                                                                                object
                                                                                                                to
                                                                                                                the
                                                                                                                processing
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                information.
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(0, 0, 0)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                You
                                                                                                                may
                                                                                                                request
                                                                                                                correction
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                                if
                                                                                                                it
                                                                                                                is
                                                                                                                incorrect
                                                                                                                or
                                                                                                                no
                                                                                                                longer
                                                                                                                relevant,
                                                                                                                or
                                                                                                                ask
                                                                                                                to
                                                                                                                restrict
                                                                                                                the
                                                                                                                processing
                                                                                                                of
                                                                                                                the
                                                                                                                information.
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(0, 0, 0)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                You
                                                                                                                can
                                                                                                                designate
                                                                                                                an{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                agent
                                                                                                                to
                                                                                                                make
                                                                                                                a
                                                                                                                request
                                                                                                                under
                                                                                                                the
                                                                                                                CCPA
                                                                                                                on
                                                                                                                your
                                                                                                                behalf.
                                                                                                                We
                                                                                                                may
                                                                                                                deny
                                                                                                                a
                                                                                                                request
                                                                                                                from
                                                                                                                an{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                agent
                                                                                                                that
                                                                                                                does
                                                                                                                not
                                                                                                                submit
                                                                                                                proof
                                                                                                                that
                                                                                                                they
                                                                                                                have
                                                                                                                been
                                                                                                                validly{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                to
                                                                                                                act
                                                                                                                on
                                                                                                                your
                                                                                                                behalf
                                                                                                                in
                                                                                                                accordance
                                                                                                                with
                                                                                                                the
                                                                                                                CCPA.
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(0, 0, 0)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15,
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <ul>
                                                                                                        <li
                                                                                                          style={{
                                                                                                            lineHeight:
                                                                                                              "1.5"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                You
                                                                                                                may
                                                                                                                request
                                                                                                                to
                                                                                                                opt
                                                                                                                out
                                                                                                                from
                                                                                                                future
                                                                                                                selling
                                                                                                                or
                                                                                                                sharing
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                information
                                                                                                                to
                                                                                                                third
                                                                                                                parties.
                                                                                                                Upon
                                                                                                                receiving
                                                                                                                an
                                                                                                                opt-out
                                                                                                                request,
                                                                                                                we
                                                                                                                will
                                                                                                                act
                                                                                                                upon
                                                                                                                the
                                                                                                                request
                                                                                                                as
                                                                                                                soon
                                                                                                                as
                                                                                                                feasibly
                                                                                                                possible,
                                                                                                                but
                                                                                                                no
                                                                                                                later
                                                                                                                than
                                                                                                                fifteen
                                                                                                                (15)
                                                                                                                days
                                                                                                                from
                                                                                                                the
                                                                                                                date
                                                                                                                of
                                                                                                                the
                                                                                                                request
                                                                                                                submission.
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(0, 0, 0)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              To
                                                                                                              exercise
                                                                                                              these
                                                                                                              rights,
                                                                                                              you
                                                                                                              can
                                                                                                              contact
                                                                                                              us&nbsp;
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="block-component" />
                                                                                                                    by
                                                                                                                    email
                                                                                                                    at{" "}
                                                                                                                    <bdt className="question">
                                                                                                                      contact@theilker.com
                                                                                                                    </bdt>
                                                                                                                    ,{" "}
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                    <bdt className="block-component" />
                                                                                                                  </span>
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="block-component" />
                                                                                                                    <bdt className="block-component">
                                                                                                                      <span data-custom-class="body_text" />
                                                                                                                    </bdt>{" "}
                                                                                                                    <bdt className="question">
                                                                                                                      Message
                                                                                                                      Scuttler#001
                                                                                                                      on
                                                                                                                      Discord
                                                                                                                    </bdt>
                                                                                                                    ,{" "}
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          or
                                                                                                          by
                                                                                                          referring
                                                                                                          to
                                                                                                          the
                                                                                                          contact
                                                                                                          details
                                                                                                          at
                                                                                                          the
                                                                                                          bottom
                                                                                                          of
                                                                                                          this
                                                                                                          document.
                                                                                                          If
                                                                                                          you
                                                                                                          have
                                                                                                          a
                                                                                                          complaint
                                                                                                          about
                                                                                                          how
                                                                                                          we
                                                                                                          handle
                                                                                                          your
                                                                                                          data,
                                                                                                          we
                                                                                                          would
                                                                                                          like
                                                                                                          to
                                                                                                          hear
                                                                                                          from
                                                                                                          you.
                                                                                                        </span>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <bdt className="block-component">
                                                                                                              <bdt className="block-component">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                fontSize: 15
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                    fontSize: 15
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                    </bdt>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </bdt>
                                                                                                            </bdt>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        id="policyupdates"
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(127, 127, 127)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  id="control"
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <strong>
                                                                                                                    <span data-custom-class="heading_1">
                                                                                                                      12.
                                                                                                                      DO
                                                                                                                      WE
                                                                                                                      MAKE
                                                                                                                      UPDATES
                                                                                                                      TO
                                                                                                                      THIS
                                                                                                                      NOTICE?
                                                                                                                    </span>
                                                                                                                  </strong>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <em>
                                                                                                                <strong>
                                                                                                                  In
                                                                                                                  Short:&nbsp;
                                                                                                                </strong>
                                                                                                                Yes,
                                                                                                                we
                                                                                                                will
                                                                                                                update
                                                                                                                this
                                                                                                                notice
                                                                                                                as
                                                                                                                necessary
                                                                                                                to
                                                                                                                stay
                                                                                                                compliant
                                                                                                                with
                                                                                                                relevant
                                                                                                                laws.
                                                                                                              </em>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              We
                                                                                                              may
                                                                                                              update
                                                                                                              this
                                                                                                              privacy
                                                                                                              notice
                                                                                                              from
                                                                                                              time
                                                                                                              to
                                                                                                              time.
                                                                                                              The
                                                                                                              updated
                                                                                                              version
                                                                                                              will
                                                                                                              be
                                                                                                              indicated
                                                                                                              by
                                                                                                              an
                                                                                                              updated{" "}
                                                                                                              <bdt className="block-component" />
                                                                                                              "Revised"
                                                                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                              date
                                                                                                              and
                                                                                                              the
                                                                                                              updated
                                                                                                              version
                                                                                                              will
                                                                                                              be
                                                                                                              effective
                                                                                                              as
                                                                                                              soon
                                                                                                              as
                                                                                                              it
                                                                                                              is
                                                                                                              accessible.
                                                                                                              If
                                                                                                              we
                                                                                                              make
                                                                                                              material
                                                                                                              changes
                                                                                                              to
                                                                                                              this
                                                                                                              privacy
                                                                                                              notice,
                                                                                                              we
                                                                                                              may
                                                                                                              notify
                                                                                                              you
                                                                                                              either
                                                                                                              by
                                                                                                              prominently
                                                                                                              posting
                                                                                                              a
                                                                                                              notice
                                                                                                              of
                                                                                                              such
                                                                                                              changes
                                                                                                              or
                                                                                                              by
                                                                                                              directly
                                                                                                              sending
                                                                                                              you
                                                                                                              a
                                                                                                              notification.
                                                                                                              We
                                                                                                              encourage
                                                                                                              you
                                                                                                              to
                                                                                                              review
                                                                                                              this
                                                                                                              privacy
                                                                                                              notice
                                                                                                              frequently
                                                                                                              to
                                                                                                              be
                                                                                                              informed
                                                                                                              of
                                                                                                              how
                                                                                                              we
                                                                                                              are
                                                                                                              protecting
                                                                                                              your
                                                                                                              information.
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        id="contact"
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(127, 127, 127)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  id="control"
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <strong>
                                                                                                                    <span data-custom-class="heading_1">
                                                                                                                      13.
                                                                                                                      HOW
                                                                                                                      CAN
                                                                                                                      YOU
                                                                                                                      CONTACT
                                                                                                                      US
                                                                                                                      ABOUT
                                                                                                                      THIS
                                                                                                                      NOTICE?
                                                                                                                    </span>
                                                                                                                  </strong>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              If
                                                                                                              you
                                                                                                              have
                                                                                                              questions
                                                                                                              or
                                                                                                              comments
                                                                                                              about
                                                                                                              this
                                                                                                              notice,
                                                                                                              you
                                                                                                              may{" "}
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="block-component" />
                                                                                                                  email
                                                                                                                  us
                                                                                                                  at{" "}
                                                                                                                  <bdt className="question">
                                                                                                                    contact@theilker.com
                                                                                                                  </bdt>
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                              </span>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text" />
                                                                                                                  <bdt className="block-component">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text" />
                                                                                                                    </span>
                                                                                                                  </bdt>
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <bdt className="statement-end-if-in-editor">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <bdt className="block-component">
                                                                                                                                    <bdt className="block-component" />
                                                                                                                                  </bdt>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </bdt>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                                <bdt className="block-component" />
                                                                                                                              </bdt>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        id="request"
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(127, 127, 127)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  id="control"
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(0, 0, 0)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <strong>
                                                                                                                    <span data-custom-class="heading_1">
                                                                                                                      14.
                                                                                                                      HOW
                                                                                                                      CAN
                                                                                                                      YOU
                                                                                                                      REVIEW,
                                                                                                                      UPDATE,
                                                                                                                      OR
                                                                                                                      DELETE
                                                                                                                      THE
                                                                                                                      DATA
                                                                                                                      WE
                                                                                                                      COLLECT
                                                                                                                      FROM
                                                                                                                      YOU?
                                                                                                                    </span>
                                                                                                                  </strong>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <br />
                                                                                                      </div>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          lineHeight:
                                                                                                            "1.5"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <bdt className="block-component" />
                                                                                                              Based
                                                                                                              on
                                                                                                              the
                                                                                                              applicable
                                                                                                              laws
                                                                                                              of
                                                                                                              your
                                                                                                              country,
                                                                                                              you
                                                                                                              may
                                                                                                              have
                                                                                                              the
                                                                                                              right
                                                                                                              to
                                                                                                              request
                                                                                                              access
                                                                                                              to
                                                                                                              the
                                                                                                              personal
                                                                                                              information
                                                                                                              we
                                                                                                              collect
                                                                                                              from
                                                                                                              you,
                                                                                                              change
                                                                                                              that
                                                                                                              information,
                                                                                                              or
                                                                                                              delete
                                                                                                              it.{" "}
                                                                                                              <bdt className="else-block" />
                                                                                                              To
                                                                                                              request
                                                                                                              to
                                                                                                              review,
                                                                                                              update,
                                                                                                              or
                                                                                                              delete
                                                                                                              your
                                                                                                              personal
                                                                                                              information,
                                                                                                              please{" "}
                                                                                                              <bdt className="block-component" />
                                                                                                              submit
                                                                                                              a
                                                                                                              request
                                                                                                              form
                                                                                                              by
                                                                                                              clicking&nbsp;
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(48, 48, 241)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <a
                                                                                                                    data-custom-class="link"
                                                                                                                    href="https://forms.gle/ukCh3MiNsGLpRHdL8"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    target="_blank"
                                                                                                                  >
                                                                                                                    here
                                                                                                                  </a>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span data-custom-class="body_text" />
                                                                                                            </bdt>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                        <span data-custom-class="body_text">
                                                                                                          .
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <style
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                          __html:
                                                                                                            "\n        ul {\n          list-style-type: square;\n        }\n        ul > li > ul {\n          list-style-type: circle;\n        }\n        ul > li > ul > li > ul {\n          list-style-type: square;\n        }\n        ol li {\n          font-family: Arial ;\n        }\n      "
                                                                                                        }}
                                                                                                      />
                                                                                                    </div>
                                                                                                  </div>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <noscript>Your browser does not support JavaScript!</noscript>
  <div style={{ clear: "both", display: "block", height: 0 }} />
</>} />
        <Route path="/terms-of-service" element={<>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        * {\n            background: #36393f !important;\n        }\n    "
    }}
  />
  <div id="hosted">
    <div data-iframe-height="true">
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n                [data-custom-class='body'],\n                [data-custom-class='body'] * {\n                    background: #36393f !important;\n                }\n\n                [data-custom-class='title'],\n                [data-custom-class='title'] * {\n                    font-family: Arial !important;\n                    font-size: 26px !important;\n                    color: #ffffff !important;\n                }\n\n                [data-custom-class='subtitle'],\n                [data-custom-class='subtitle'] * {\n                    font-family: Arial !important;\n                    color: #ffffff !important;\n                    font-size: 14px !important;\n                }\n\n                [data-custom-class='heading_1'],\n                [data-custom-class='heading_1'] * {\n                    font-family: Arial !important;\n                    font-size: 19px !important;\n                    color: #ffffff !important;\n                }\n\n                [data-custom-class='heading_2'],\n                [data-custom-class='heading_2'] * {\n                    font-family: Arial !important;\n                    font-size: 17px !important;\n                    color: #ffffff !important;\n                }\n\n                [data-custom-class='body_text'],\n                [data-custom-class='body_text'] * {\n                    color: #ffffff !important;\n                    font-size: 14px !important;\n                    font-family: Arial !important;\n                }\n\n                [data-custom-class='link'],\n                [data-custom-class='link'] * {\n                    color: #4a90e2 !important;\n                    font-size: 14px !important;\n                    font-family: Arial !important;\n                    word-break: break-word !important;\n                }\n            "
        }}
      />
      <div data-custom-class="body">
        <div align="center" style={{ textAlign: "left" }}>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="title"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
            <strong>
              <span style={{ lineHeight: "22.5px", fontSize: 26 }}>
                <bdt className="block-component" />
                <bdt className="question">TERMS AND CONDITIONS</bdt>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </strong>
          </div>
          <div
            align="center"
            className="MsoNormal"
            style={{ textAlign: "center", lineHeight: "22.5px" }}
          >
            <a name="_7m5b3xg56u7y" />
          </div>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="subtitle"
            style={{ textAlign: "left", lineHeight: "150%" }}
          >
            <br />
          </div>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="subtitle"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
            <span
              style={{
                color: "rgb(89, 89, 89)",
                fontSize: "14.6667px",
                textAlign: "justify"
              }}
            >
              <strong>
                Last updated{" "}
                <bdt
                  className="block-container question question-in-editor"
                  data-id="6d5ec16f-716c-32d6-1aa6-f4c1bd8cce1f"
                  data-type="question"
                >
                  September 02, 2024
                </bdt>
              </strong>
            </span>
            <br />
            <a name="_gm5sejt4p02f" />
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <br />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#agreement">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  1. AGREEMENT TO TERMS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#ip">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  2. INTELLECTUAL PROPERTY RIGHTS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#userreps">
                  3. USER REPRESENTATIONS
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#userreg">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="user_account_option"
                      data-type="statement"
                    >
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <a data-custom-class="link" href="#userreg">
              <span style={{ fontSize: 15 }} />
            </a>
            <a data-custom-class="link" href="#products">
              <span style={{ color: "rgb(89, 89, 89)" }}>4. PRODUCTS</span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#purchases">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  5. PURCHASES AND PAYMENT
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt
                  className="block-component"
                  data-record-question-key="return_option"
                  data-type="statement"
                  style={{ fontSize: 15 }}
                />
              </bdt>
            </span>
            <a data-custom-class="link" href="#returnrefunds">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                6. <bdt className="block-component" />
                REFUNDS
                <bdt className="else-block" /> POLICY
              </span>
            </a>
            <a data-custom-class="link" href="#returnrefunds" />
            <a data-custom-class="link" href="#returnrefunds">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </a>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#prohibited">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  7. PROHIBITED ACTIVITIES
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#ugc">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  8. USER GENERATED CONTRIBUTIONS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#license">
                  9. CONTRIBUTION LICENSE
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#reviews">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="review_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#mobile">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="mobile_app_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#submissions">
                  10. SUBMISSIONS
                </a>
              </span>
            </span>
          </div>
          <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#thirdparty">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  11. THIRD-PARTY WEBSITE AND CONTENT
                </span>
              </a>
              <a data-custom-class="link" href="#agreement">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" data-type="close">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#advertisers">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="14038561-dad7-be9d-370f-f8aa487b2570"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="advertiser_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#sitemanage">
                  12. SITE MANAGEMENT
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#privacypolicy">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="privacy_policy_option"
                        data-type="statement"
                      >
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                    </bdt>
                  </bdt>
                  13. PRIVACY POLICY
                </span>
              </a>
              <a data-custom-class="link" href="#advertisers" />
              <a data-custom-class="link" href="#agreement">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" data-type="close">
                    <span style={{ fontSize: 15 }}>
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="87a7471d-cf82-1032-fdf8-601d37d7b017"
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="privacy_policy_followup"
                            data-type="statement"
                            style={{ fontSize: "14.6667px" }}
                          >
                            <span style={{ fontSize: 15 }} />
                          </bdt>
                        </bdt>
                      </bdt>
                    </span>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#dmca">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component">
                    <bdt className="block-component" />
                    <bdt className="block-component">
                      <bdt className="block-container if" data-type="if">
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                      </bdt>
                    </bdt>
                  </bdt>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <bdt className="block-component" />
            <bdt className="block-container if" data-type="if">
              <bdt className="statement-end-if-in-editor" data-type="close">
                <span style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </span>
              </bdt>
            </bdt>
            <bdt className="block-component" />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#terms">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  14. TERM AND TERMINATION
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#modifications">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  15. MODIFICATIONS AND INTERRUPTIONS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#law">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  16. GOVERNING LAW
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#disputes">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  17. DISPUTE RESOLUTION
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#corrections">
                  18. CORRECTIONS
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#disclaimer">
                <span style={{ color: "rgb(89, 89, 89)" }}>19. DISCLAIMER</span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#liability">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  20. LIMITATIONS OF LIABILITY
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#indemnification">
                  21. INDEMNIFICATION
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#userdata">
                <span style={{ color: "rgb(89, 89, 89)" }}>22. USER DATA</span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#electronic">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </span>
              </a>
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ lineHeight: "1.5", textAlign: "left" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="block-component" />
            </span>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#california">
                  24. CALIFORNIA USERS AND RESIDENTS
                </a>
                <a data-custom-class="link" href="#agreement">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    >
                      <span style={{ fontSize: 15 }} />
                    </bdt>
                  </span>
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#misc">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  25. MISCELLANEOUS
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <a data-custom-class="link" href="#contact">
                  26. CONTACT US
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <br />
          </div>
          <div style={{ lineHeight: "1.5", textAlign: "left" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="agreement"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_6aa3gkhykvst" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    1.&nbsp;
                  </span>
                </strong>
                AGREEMENT TO TERMS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you”)
              and{" "}
              <bdt
                className="block-container question question-in-editor"
                data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
                data-type="question"
              >
                Oreki Bot
              </bdt>
              <bdt className="block-component" /> ("
              <bdt className="block-component" />
              <strong>Company</strong>
              <bdt className="statement-end-if-in-editor" />
              ", “<strong>we</strong>”, “<strong>us</strong>”, or “
              <strong>our</strong>”), concerning your access to and use of the{" "}
              <bdt
                className="block-container question question-in-editor"
                data-id="fdf30452-99b8-c68b-5cdf-34af764cd1fd"
                data-type="question"
              >
                <a
                  href="https://oreki.theilker.com"
                  target="_blank"
                  data-custom-class="link"
                >
                  https://oreki.theilker.com
                </a>
              </bdt>{" "}
              website as well as any other media form, media channel, mobile
              website or mobile application related, linked, or otherwise
              connected thereto (collectively, the “Site”).
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166
                }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166
                  }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166
                    }}
                  >
                    <bdt className="question">
                      <bdt className="block-component" />
                    </bdt>
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        msoFareastFontFamily: "Calibri",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166
                      }}
                    >
                      <bdt className="block-component" />
                    </span>{" "}
                    We are registered in
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />{" "}
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        msoFareastFontFamily: "Calibri",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11.0pt",
                          lineHeight: "115%",
                          msoFareastFontFamily: "Calibri",
                          color: "#595959",
                          msoThemecolor: "text1",
                          msoThemetint: 166
                        }}
                      >
                        <bdt className="question">California</bdt>,{" "}
                        <bdt className="question">United States</bdt>
                      </span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>{" "}
            and have our registered office at{" "}
            <bdt className="question">
              14252 Culver Dr, Irvine, CA 92604, USA
            </bdt>
            <bdt className="block-component" />,{" "}
            <bdt className="question">Ste A#248</bdt>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166
                }}
              >
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            , <bdt className="question">Irvine</bdt>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                      <bdt className="block-component" />,{" "}
                      <bdt className="question">CA</bdt>
                      <bdt className="statement-end-if-in-editor" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                      <bdt className="question">92604</bdt>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                msoFareastFontFamily: "Calibri",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  msoFareastFontFamily: "Calibri",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166
                }}
              >
                <bdt className="statement-end-if-in-editor">.</bdt>
                <bdt className="else-block" />
              </span>
            </span>
            <bdt className="statement-end-if-in-editor" />
            <bdt className="block-component" /> You agree that by accessing the
            Site, you have read, understood, and agreed to be bound by all of
            these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF
            USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU
            MUST DISCONTINUE USE IMMEDIATELY.
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use{" "}
              <bdt className="block-component" />
              from time to time
              <bdt className="else-block" />. We will alert you about any
              changes by updating the “Last updated” date of these Terms of Use,
              and you waive any right to receive specific notice of each such
              change. Please ensure that you check the applicable Terms every
              time you use our Site so that you understand which Terms apply.
              You will be subject to, and will be deemed to have been made aware
              of and to have accepted, the changes in any revised Terms of Use
              by your continued use of the Site after the date such revised
              Terms of Use are posted.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "115%" }}>
            <bdt className="block-component" />
            <bdt className="block-component">
              <bdt className="block-component" />
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <span style={{ fontSize: 15 }}>
              <br />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              The{" "}
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                Site is not tailored to comply with industry-specific
                regulations (Health Insurance Portability and Accountability Act
                (HIPAA), Federal Information Security Management Act (FISMA),
                etc.), so if your interactions would be subjected to such laws,
                you may not use this Site. You may not use the Site in a way
                that would violate the Gramm-Leach-Bliley Act (GLBA).
              </span>
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <bdt className="block-component" />
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                >
                  <bdt
                    className="statement-end-if-in-editor"
                    data-type="close"
                  />
                </bdt>
              </span>
            </span>
          </div>
        </div>
        <div
          align="center"
          data-custom-class="body_text"
          style={{ textAlign: "left", lineHeight: 1 }}
        >
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              id="a2595956-7028-dbe5-123e-d3d3a93ed076"
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166
                    }}
                  >
                    <bdt className="block-component" />
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                    >
                      <bdt data-type="conditional-block">
                        <bdt data-type="body">
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "14.6667px"
                            }}
                          >
                            All users who are minors in the jurisdiction in
                            which they reside (generally under the age of 18)
                            must have the permission of, and be directly
                            supervised by, their parent or guardian to use the
                            Site. If you are a minor, you must have your parent
                            or guardian read and agree to these Terms of Use
                            prior to you using the Site.
                          </span>
                        </bdt>
                      </bdt>
                    </bdt>
                  </span>
                </bdt>
              </bdt>
              <bdt className="statement-end-if-in-editor" data-type="close" />
            </bdt>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="ip"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_b6y29mp52qvx" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            2.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                INTELLECTUAL PROPERTY RIGHTS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              Unless otherwise indicated, the Site is our proprietary property
              and all source code, databases, functionality, software, website
              designs, audio, video, text, photographs, and graphics on the Site
              (collectively, the “Content”) and the trademarks, service marks,
              and logos contained therein (the “Marks”) are owned or controlled
              by us or licensed to us, and are protected by copyright and
              trademark laws and various other intellectual property rights and
              unfair competition laws of the United States, international
              copyright laws, and international conventions. The Content and the
              Marks are provided on the Site “AS IS” for your information and
              personal use only. Except as expressly provided in these Terms of
              Use, no part of the Site and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="userreps"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_5hg7kgyv9l8z" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            3.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                USER REPRESENTATIONS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              By using the Site, you represent and warrant that:
            </span>
            <bdt
              className="block-container if"
              data-type="if"
              id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                />
                &nbsp;
              </bdt>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                1
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you have the legal capacity and you agree to comply with these
                Terms of Use;
              </span>
              <bdt
                className="block-container if"
                data-type="if"
                id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_u13_option"
                    data-type="statement"
                  />
                  &nbsp;
                </bdt>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                  (
                </span>
                <span
                  style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                >
                  2
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                  ) you are not a minor in the jurisdiction in which you reside
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="76948fab-ec9e-266a-bb91-948929c050c9"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="user_o18_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        , or if a minor, you have received parental permission
                        to use the Site
                      </bdt>
                    </bdt>
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  ; (
                </span>
                <span
                  style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                >
                  3
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                  ) you will not access the Site through automated or non-human
                  means, whether through a bot, script or otherwise; (
                </span>
                <span
                  style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                >
                  4
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                  ) you will not use the Site for any illegal or unauthorized
                  purpose; and (
                </span>
                <span
                  style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                >
                  5
                </span>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                  ) your use of the Site will not violate any applicable law or
                  regulation.
                </span>
                <span
                  style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                />
              </bdt>
            </bdt>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Site (or any portion thereof).
                </span>
              </div>
              <div
                className="MsoNormal"
                style={{ lineHeight: "1.1", textAlign: "left" }}
              >
                <bdt className="block-component" />
              </div>
            </div>
            <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <br />
            </div>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="products"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_nds4qylockxx" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            4.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                PRODUCTS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt
                className="block-container if"
                data-type="if"
                id="b9812c02-490b-3a1e-9b3a-9a03c73ee63c"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="product_option"
                    data-type="statement"
                  />
                </bdt>
                All products are subject to availability
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="35c07bc8-4217-594b-b7e7-1b4f0e8c56e4"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="product_option"
                      data-type="statement"
                    />
                  </bdt>
                  . We reserve the right to discontinue any products at any time
                  for any reason. Prices for all products are subject to change.
                </bdt>
              </bdt>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="purchases"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_ynub0jdx8pob" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: 19
                }}
              >
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            5.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                PURCHASES AND PAYMENT
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              We accept the following forms of payment:
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <div
              className="MsoNormal"
              style={{ textAlign: "left", lineHeight: 1 }}
            >
              <br />
            </div>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: 20 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="forloop-component" />- &nbsp;
              <bdt className="question">Visa</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: 20 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="forloop-component" />- &nbsp;
              <bdt className="question">Mastercard</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: 20 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="forloop-component" />- &nbsp;
              <bdt className="question">American Express</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: 20 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="forloop-component" />- &nbsp;
              <bdt className="question">Discover</bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5", marginLeft: 20 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <bdt className="forloop-component" />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <br />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              You agree to provide current, complete, and accurate purchase and
              account information for all purchases made via the Site. You
              further agree to promptly update account and payment information,
              including email address, payment method, and payment card
              expiration date, so that we can complete your transactions and
              contact you as needed. Sales tax will be added to the price of
              purchases as deemed required by us. We may change prices at any
              time. All payments shall be&nbsp;
            </span>
            <span
              style={{
                fontSize: 15,
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              in <bdt className="question">U.S. dollars</bdt>.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order.{" "}
              <bdt
                className="block-container if"
                data-type="if"
                id="9c0216a1-d094-fd73-a062-9615dc795ffc"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="recurring_charge_option"
                    data-type="statement"
                  />
                </bdt>
                We reserve the right to correct any errors or mistakes in
                pricing, even if we have already requested or received payment.
              </bdt>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              We reserve the right to refuse any order placed through the Site.
              We may, in our sole discretion, limit or cancel quantities
              purchased per person, per household, or per order. These
              restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole judgment, appear to be
              placed by dealers, resellers, or distributors.
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt
                  className="block-component"
                  data-record-question-key="return_option"
                  data-type="statement"
                  style={{ fontSize: 15 }}
                />
              </bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            id="returnrefunds"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    style={{ lineHeight: "1.5" }}
                  >
                    <strong>
                      <span style={{ lineHeight: "24.5333px" }}>
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="09a19ea5-53d7-8b08-be6e-279bf01450e1"
                        >
                          <bdt data-type="conditional-block">
                            <span style={{ fontSize: 19 }}>
                              <bdt data-type="body">
                                <bdt className="block-component">
                                  <strong>
                                    <span
                                      style={{
                                        lineHeight: "115%",
                                        fontFamily: "Arial",
                                        fontSize: 19
                                      }}
                                    >
                                      <strong>
                                        <span
                                          style={{
                                            lineHeight: "115%",
                                            fontFamily: "Arial",
                                            fontSize: 19
                                          }}
                                        >
                                          <strong>
                                            <span
                                              style={{
                                                lineHeight: "115%",
                                                fontFamily: "Arial",
                                                fontSize: 19
                                              }}
                                            >
                                              6.
                                            </span>
                                          </strong>
                                        </span>
                                      </strong>
                                    </span>
                                    &nbsp;
                                  </strong>
                                </bdt>
                                <bdt data-type="body">REFUNDS</bdt>
                              </bdt>
                              <bdt data-type="conditional-block">
                                <bdt
                                  className="block-component"
                                  data-record-question-key="null"
                                  data-type="statement"
                                />
                              </bdt>
                            </span>
                          </bdt>
                          <span style={{ fontSize: 19 }}>&nbsp;POLICY</span>
                        </bdt>
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ fontSize: 15, lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      All sales are final and no refund will be issued.
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                          style={{ fontSize: 15, textAlign: "left" }}
                        />
                      </span>
                    </span>
                  </div>
                </bdt>
              </bdt>
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt
                data-type="conditional-block"
                style={{ color: "rgb(10, 54, 90)", textAlign: "left" }}
              >
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    style={{ fontSize: 15, lineHeight: "1.5" }}
                  >
                    <br />
                  </div>
                </bdt>
              </bdt>
              <br />
            </span>{" "}
            <a name="_h284p8mrs3r7" />
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="prohibited"
              style={{ textAlign: "left", lineHeight: "1.5" }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: 19
                              }}
                            >
                              7.
                            </span>
                          </strong>
                        </span>
                      </strong>
                    </span>
                    &nbsp;
                  </strong>
                  PROHIBITED ACTIVITIES
                </span>
              </strong>
            </div>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5", textAlign: "left" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)"
                }}
              >
                You may not access or use the Site for any purpose other than
                that for which we make the Site available. The Site may not be
                used in connection with any commercial endeavors except those
                that are specifically endorsed or approved by us.
              </span>
            </div>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
                <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      As a user of the Site, you agree not to:
                    </span>
                  </div>
                </div>
                <ul>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: 15,
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: 0,
                          WebkitTextStrokeWidth: 0,
                          backgroundColor: "rgb(255, 255, 255)",
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        Systematically retrieve data or other content from the
                        Site to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Trick, defraud, or mislead us and other users,
                              especially in any attempt to learn sensitive
                              account information such as user passwords.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Circumvent, disable, or otherwise interfere with
                              security-related features of the Site, including
                              features that prevent or restrict the use or
                              copying of any Content or enforce limitations on
                              the use of the Site and/or the Content contained
                              therein.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Disparage, tarnish, or otherwise harm, in our
                              opinion, us and/or the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Use any information obtained from the Site in
                              order to harass, abuse, or harm another person.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Make improper use of our support services or
                              submit false reports of abuse or misconduct.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Use the Site in a manner inconsistent with any
                              applicable laws or regulations.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Engage in unauthorized framing of or linking to
                              the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Upload or transmit (or attempt to upload or to
                              transmit) viruses, Trojan horses, or other
                              material, including excessive use of capital
                              letters and spamming (continuous posting of
                              repetitive text), that interferes with any party’s
                              uninterrupted use and enjoyment of the Site or
                              modifies, impairs, disrupts, alters, or interferes
                              with the use, features, functions, operation, or
                              maintenance of the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Engage in any automated use of the system, such as
                              using scripts to send comments or messages, or
                              using any data mining, robots, or similar data
                              gathering and extraction tools.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Delete the copyright or other proprietary rights
                              notice from any Content.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Attempt to impersonate another user or person or
                              use the username of another user.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Upload or transmit (or attempt to upload or to
                              transmit) any material that acts as a passive or
                              active information collection or transmission
                              mechanism, including without limitation, clear
                              graphics interchange formats (“gifs”), 1×1 pixels,
                              web bugs, cookies, or other similar devices
                              (sometimes referred to as “spyware” or “passive
                              collection mechanisms” or “pcms”).
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Interfere with, disrupt, or create an undue burden
                              on the Site or the networks or services connected
                              to the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Harass, annoy, intimidate, or threaten any of our
                              employees or agents engaged in providing any
                              portion of the Site to you.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Attempt to bypass any measures of the Site
                              designed to prevent or restrict access to the
                              Site, or any portion of the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Copy or adapt the Site’s software, including but
                              not limited to Flash, PHP, HTML, JavaScript, or
                              other code.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Except as permitted by applicable law, decipher,
                              decompile, disassemble, or reverse engineer any of
                              the software comprising or in any way making up a
                              part of the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Except as may be the result of standard search
                              engine or Internet browser usage, use, launch,
                              develop, or distribute any automated system,
                              including without limitation, any spider, robot,
                              cheat utility, scraper, or offline reader that
                              accesses the Site, or using or launching any
                              unauthorized script or other software.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Use a buying agent or purchasing agent to make
                              purchases on the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Make any unauthorized use of the Site, including
                              collecting usernames and/or email addresses of
                              users by electronic or other means for the purpose
                              of sending unsolicited email, or creating user
                              accounts by automated means or under false
                              pretenses.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: 15 }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)"
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: 0,
                            WebkitTextStrokeWidth: 0,
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)"
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: 0,
                                WebkitTextStrokeWidth: 0,
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)"
                              }}
                            >
                              Use the Site as part of any effort to compete with
                              us or otherwise use the Site and/or the Content
                              for any revenue-generating endeavor or commercial
                              enterprise.
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <bdt className="forloop-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <bdt className="question">
                        Use the Site to advertise or offer to sell goods and
                        services.
                      </bdt>
                    </span>
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <bdt className="forloop-component" />
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <bdt className="question">
                        Sell or otherwise transfer your profile.
                      </bdt>
                    </span>
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <bdt className="forloop-component" />
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <bdt className="question">
                        Uploading inappropriate text or images
                      </bdt>
                    </span>
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      <bdt className="forloop-component" />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                <br />
              </div>
            </div>
            <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <bdt
                  className="block-container if"
                  data-type="if"
                  style={{ textAlign: "left" }}
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-class="heading_1"
                        id="ugc"
                        style={{ lineHeight: "1.5" }}
                      >
                        <strong>
                          <span
                            style={{ lineHeight: "24.5333px", fontSize: 19 }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "24.5333px",
                                  fontSize: 19
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "115%",
                                      fontFamily: "Arial",
                                      fontSize: 19
                                    }}
                                  >
                                    <strong>
                                      <span
                                        style={{
                                          lineHeight: "115%",
                                          fontFamily: "Arial",
                                          fontSize: 19
                                        }}
                                      >
                                        8.
                                      </span>
                                    </strong>
                                  </span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            USER GENERATED CONTRIBUTIONS
                          </span>
                        </strong>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <br />
              </div>
              <div className="MsoNormal" style={{ lineHeight: 1 }}>
                <bdt
                  className="block-container if"
                  data-type="if"
                  style={{ textAlign: "left" }}
                >
                  <bdt data-type="conditional-block">
                    <bdt data-type="body">
                      <div
                        className="MsoNormal"
                        data-custom-class="body_text"
                        style={{ lineHeight: "1.5" }}
                      >
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)"
                          }}
                        >
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
                            style={{ textAlign: "left" }}
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="user_post_content_option"
                                data-type="statement"
                              />
                            </bdt>
                          </bdt>
                          The Site may invite you to chat, contribute to, or
                          participate in blogs, message boards, online forums,
                          and other functionality, and may provide you with the
                          opportunity to create, submit, post, display,
                          transmit, perform, publish, distribute, or broadcast
                          content and materials to us or on the Site, including
                          but not limited to text, writings, video, audio,
                          photographs, graphics, comments, suggestions, or
                          personal information or other material (collectively,
                          "Contributions"). Contributions may be viewable by
                          other users of the Site and the Marketplace Offerings
                          and through third-party websites. As such, any
                          Contributions you transmit may be treated as
                          non-confidential and non-proprietary. When you create
                          or make available any Contributions, you thereby
                          represent and warrant that:
                          <bdt className="else-block" />
                        </span>
                      </div>
                    </bdt>
                  </bdt>
                </bdt>
              </div>
            </div>
          </div>
          <ul>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not infringe the
                    proprietary rights, including but not limited to the
                    copyright, patent, trademark, trade secret, or moral rights
                    of any third party.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Site, and other users of the Site
                    to use your Contributions in any manner contemplated by the
                    Site and these Terms of Use.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    You have the written consent, release, and/or permission of
                    each and every identifiable individual person in your
                    Contributions to use the name or likeness of each and every
                    such identifiable individual person to enable inclusion and
                    use of your Contributions in any manner contemplated by the
                    Site and these Terms of Use.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not false, inaccurate, or misleading.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not violate any applicable law,
                    regulation, or rule.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not violate the privacy or publicity
                    rights of any third party.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors;
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                  </span>
                </span>
              </span>
            </li>
            <li className="MsoNormal" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 14 }}>
                  <span data-custom-class="body_text">
                    Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Terms of Use,
                    or any applicable law or regulation.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      Any use of the Site or the Marketplace Offerings in
                      violation of the foregoing violates these Terms of Use and
                      may result in, among other things, termination or
                      suspension of your rights to use the Site and the
                      Marketplace Offerings.
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="license"
                    style={{ lineHeight: "1.5" }}
                  >
                    <strong>
                      <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                        <strong>
                          <span
                            style={{ lineHeight: "24.5333px", fontSize: 19 }}
                          >
                            <strong>
                              <span
                                style={{
                                  lineHeight: "115%",
                                  fontFamily: "Arial",
                                  fontSize: 19
                                }}
                              >
                                <strong>
                                  <span
                                    style={{
                                      lineHeight: "115%",
                                      fontFamily: "Arial",
                                      fontSize: 19
                                    }}
                                  >
                                    9.
                                  </span>
                                </strong>
                              </span>
                            </strong>
                          </span>
                          &nbsp;
                        </strong>
                        CONTRIBUTION LICENSE
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_post_content_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      By posting your Contributions to any part of the Site
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="19652acc-9a2a-5ffe-6189-9474402fa6cc"
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="socialnetwork_link_option"
                            data-type="statement"
                          />
                        </bdt>
                        , you automatically grant, and you represent and warrant
                        that you have the right to grant, to us an unrestricted,
                        unlimited, irrevocable, perpetual, non-exclusive,
                        transferable, royalty-free, fully-paid, worldwide right,
                        and license to host, use, copy, reproduce, disclose,
                        sell, resell, publish, broadcast, retitle, archive,
                        store, cache, publicly perform, publicly display,
                        reformat, translate, transmit, excerpt (in whole or in
                        part), and distribute such Contributions (including,
                        without limitation, your image and voice) for any
                        purpose, commercial, advertising, or otherwise, and to
                        prepare derivative works of, or incorporate into other
                        works, such Contributions, and grant and authorize
                        sublicenses of the foregoing. The use and distribution
                        may occur in any media formats and through any media
                        channels.
                      </bdt>
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      This license will apply to any form, media, or technology
                      now known or hereafter developed, and includes our use of
                      your name, company name, and franchise name, as
                      applicable, and any of the trademarks, service marks,
                      trade names, logos, and personal and commercial images you
                      provide. You waive all moral rights in your Contributions,
                      and you warrant that moral rights have not otherwise been
                      asserted in your Contributions.
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      We do not assert any ownership over your Contributions.
                      You retain full ownership of all of your Contributions and
                      any intellectual property rights or other proprietary
                      rights associated with your Contributions. We are not
                      liable for any statements or representations in your
                      Contributions provided by you in any area on the Site. You
                      are solely responsible for your Contributions to the Site
                      and you expressly agree to exonerate us from any and all
                      responsibility and to refrain from any legal action
                      against us regarding your Contributions.
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)"
                      }}
                    >
                      We have the right, in our sole and absolute discretion,
                      (1) to edit, redact, or otherwise change any
                      Contributions; (2) to re-categorize any Contributions to
                      place them in more appropriate locations on the Site; and
                      (3) to pre-screen or delete any Contributions at any time
                      and for any reason, without notice. We have no obligation
                      to monitor your Contributions.
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt className="else-block" />
                  </span>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="review_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="mobile_app_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt
              className="block-container if"
              data-type="if"
              style={{ textAlign: "left" }}
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="socialnetwork_link_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="submissions"
          style={{ lineHeight: "1.1" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          10.
                        </span>
                      </strong>
                    </span>
                    &nbsp;
                  </strong>
                </span>
              </strong>
              SUBMISSIONS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.1" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site or the
          Marketplace Offerings ("Submissions") provided by you to us are
          non-confidential and shall become our sole property. We shall own
          exclusive rights, including all intellectual property rights, and
          shall be entitled to the unrestricted use and dissemination of these
          Submissions for any lawful purpose, commercial or otherwise, without
          acknowledgment or compensation to you. You hereby waive all moral
          rights to any such Submissions, and you hereby warrant that any such
          Submissions are original with you or that you have the right to submit
          such Submissions. You agree there shall be no recourse against us for
          any alleged or actual infringement or misappropriation of any
          proprietary right in your Submissions.
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="3rd_party_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="thirdparty"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <strong>
              <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        11.
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
              </span>
            </strong>
            THIRD-PARTY WEBSITES AND CONTENT
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            The Site may contain (or you may be sent via the Site or the
            Marketplace Offerings) links to other websites ("
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span>{" "}
            Websites") as well as articles, photographs, text, graphics,
            pictures, designs, music, sound, video, information, applications,
            software, and other content or items belonging to or originating
            from third parties ("Third-Party Content"). Such{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            and <span style={{ fontSize: "14.6667px" }}>Third-Party</span>{" "}
            Content are not investigated, monitored, or checked for accuracy,
            appropriateness, or completeness by us, and we are not responsible
            for any Third Party Websites accessed through the Site or any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
            posted on, available through, or installed from the Site, including
            the content, accuracy, offensiveness, opinions, reliability, privacy
            practices, or other policies of or contained in the{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            or the <span style={{ fontSize: "14.6667px" }}>Third-Party</span>{" "}
            Content. Inclusion of, linking to, or permitting the use or
            installation of any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            or any <span style={{ fontSize: "14.6667px" }}>Third-Party</span>
            Content does not imply approval or endorsement thereof by us. If you
            decide to leave the Site and access the{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            or to use or install any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content,
            you do so at your own risk, and you should be aware these Terms of
            Use no longer govern. You should review the applicable terms and
            policies, including privacy and data gathering practices, of any
            website to which you navigate from the Site or relating to any
            applications you use or install from the Site. Any purchases you
            make through{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            will be through other websites and from other companies, and we take
            no responsibility whatsoever in relation to such purchases which are
            exclusively between you and the applicable third party. You agree
            and acknowledge that we do not endorse the products or services
            offered on{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites
            and you shall hold us harmless from any harm caused by your purchase
            of such products or services. Additionally, you shall hold us
            harmless from any losses sustained by you or harm caused to you
            relating to or resulting in any way from any{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Content
            or any contact with{" "}
            <span style={{ fontSize: "14.6667px" }}>Third-Party</span> Websites.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt className="statement-end-if-in-editor" data-type="close" />
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="advertiser_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="sitemanage"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          12.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              SITE MANAGEMENT
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms of Use; (2) take appropriate legal
          action against anyone who, in our sole discretion, violates the law or
          these Terms of Use, including without limitation, reporting such user
          to law enforcement authorities; (3) in our sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the
          Site in a manner designed to protect our rights and property and to
          facilitate the proper functioning of the Site and the Marketplace
          Offerings.
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="privacy_policy_option"
                data-type="statement"
              />
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="privacypolicy"
          style={{ lineHeight: "1.5" }}
        >
          <strong>
            <strong>
              <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                <strong>
                  <span
                    style={{
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      fontSize: 19
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        13.
                      </span>
                    </strong>
                  </span>
                </strong>
              </span>
              &nbsp;
            </strong>
            PRIVACY POLICY
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1 }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            We care about data privacy and security. Please review our Privacy
            Policy:
            <strong>
              &nbsp;
              <bdt
                className="block-container question question-in-editor"
                data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1"
                data-type="question"
              >
                <a
                  href="https://oreki.theilker.com"
                  target="_blank"
                  data-custom-class="link"
                >
                  https://oreki.theilker
                </a>
                .com/privacy-policy
              </bdt>
            </strong>
            . By using the Site or the Marketplace Offerings, you agree to be
            bound by our Privacy Policy, which is incorporated into these Terms
            of Use. Please be advised the Site and the Marketplace Offerings are
            hosted in <bdt className="block-component" />
            the <bdt className="question">United States</bdt>
            <bdt className="block-component" />. If you access the Site or the
            Marketplace Offerings from any other region of the world with laws
            or other requirements governing personal data collection, use, or
            disclosure that differ from applicable laws in{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt className="block-component" />
              the <bdt className="question">United States</bdt>
              <bdt className="block-component" />
            </span>
            , then through your continued use of the Site, you are transferring
            your data to{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt className="block-component" />
              the <bdt className="question">United States</bdt>
              <bdt className="block-component" />
            </span>
            , and you expressly consent to have your data transferred to and
            processed in{" "}
            <span
              style={{
                fontSize: "11pt",
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt className="block-component" />
              the <bdt className="question">United States</bdt>
              <bdt className="block-component" />
            </span>
            .<bdt className="block-component" />
            <bdt
              className="block-container if"
              data-type="if"
              id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_u13_option"
                  data-type="statement"
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)"
                    }}
                  >
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </bdt>
              </bdt>
            </bdt>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <br />
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt className="statement-end-if-in-editor" data-type="close" />
          </bdt>
          <bdt className="block-container if" data-type="if">
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="privacy_policy_followup"
                data-type="statement"
                style={{ fontSize: "14.6667px" }}
              />
            </bdt>
          </bdt>
        </div>
        <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="copyright_agent_option"
                data-type="statement"
              >
                <bdt className="block-component" />
                <bdt className="block-component" />
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                style={{ textAlign: "left" }}
              >
                <bdt className="statement-end-if-in-editor" data-type="close" />
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
          <bdt
            className="block-container if"
            data-type="if"
            style={{ textAlign: "left" }}
          >
            <bdt className="statement-end-if-in-editor" data-type="close">
              <bdt className="block-component" />
            </bdt>
            <bdt className="block-component" />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="terms"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          14.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              TERM AND TERMINATION
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            These Terms of Use shall remain in full force and effect while you
            use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
            USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE
            MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
            ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
            LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
            CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
            REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE
            AND THE MARKETPLACE OFFERINGS OR DELETE{" "}
            <bdt
              className="block-container if"
              data-type="if"
              id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                />
              </bdt>
              ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
              WARNING, IN OUR SOLE DISCRETION.
            </bdt>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="modifications"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          15.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              MODIFICATIONS AND INTERRUPTIONS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Marketplace Offerings without notice
            at any time. We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Site or the Marketplace Offerings.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            We cannot guarantee the Site and the Marketplace Offerings will be
            available at all times. We may experience hardware, software, or
            other problems or need to perform maintenance related to the Site,
            resulting in interruptions, delays, or errors. We reserve the right
            to change, revise, update, suspend, discontinue, or otherwise modify
            the Site or the Marketplace Offerings at any time or for any reason
            without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site or the Marketplace Offerings
            during any downtime or discontinuance of the Site or the Marketplace
            Offerings. Nothing in these Terms of Use will be construed to
            obligate us to maintain and support the Site or the Marketplace
            Offerings or to supply any corrections, updates, or releases in
            connection therewith.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="law"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          16.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              GOVERNING LAW
            </span>
          </strong>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            <bdt className="block-component" />
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11pt",
              lineHeight: "16.8667px",
              color: "rgb(89, 89, 89)"
            }}
          >
            These Terms of Use and your use of the Site and the Marketplace
            Offerings are governed by and construed in accordance with the laws
            of{" "}
            <bdt
              className="block-container if"
              data-type="if"
              id="b86653c1-52f0-c88c-a218-e300b912dd6b"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="governing_law"
                  data-type="statement"
                />
                <bdt data-type="body">
                  the State of{" "}
                  <bdt
                    className="block-container question question-in-editor"
                    data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3"
                    data-type="question"
                  >
                    California
                  </bdt>
                </bdt>
              </bdt>
              <bdt className="statement-end-if-in-editor" data-type="close" />
            </bdt>{" "}
            applicable to agreements made and to be entirely performed within
            <bdt
              className="block-container if"
              data-type="if"
              id="b86653c1-52f0-c88c-a218-e300b912dd6b"
              style={{ fontSize: "14.6667px" }}
            >
              <bdt data-type="conditional-block">
                &nbsp;
                <span
                  style={{
                    fontSize: "11pt",
                    lineHeight: "16.8667px",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="governing_law"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        the State of{" "}
                        <bdt
                          className="block-container question question-in-editor"
                          data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3"
                          data-type="question"
                        >
                          California
                        </bdt>
                      </bdt>
                    </bdt>
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <span style={{ fontSize: "14.6667px" }}>,&nbsp;</span>without
                  regard to its conflict of law principles.{" "}
                  <bdt className="block-component" />
                </span>
              </bdt>
            </bdt>
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="disputes"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          17.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              DISPUTE RESOLUTION
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>Informal Negotiations</strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Terms of Use (each "Dispute"
            and collectively, the “Disputes”) brought by either you or us
            (individually, a “Party” and collectively, the “Parties”), the
            Parties agree to first attempt to negotiate any Dispute (except
            those Disputes expressly provided below) informally for at least{" "}
            <bdt className="question">thirty (30)</bdt> days before initiating
            arbitration. Such informal negotiations commence upon written notice
            from one Party to the other Party.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>Binding Arbitration</strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component">
            <span style={{ fontSize: 15 }} />
          </bdt>
          <span style={{ fontSize: 15 }}>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association ("AAA")
            and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related Disputes ("AAA Consumer Rules"), both of which are
            available at the AAA website{" "}
            <span
              style={{
                fontSize: 15,
                lineHeight: "16.8667px",
                color: "rgb(89, 89, 89)"
              }}
            >
              <a
                data-custom-class="link"
                href="http://www.adr.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.adr.org
              </a>
            </span>
            . Your arbitration fees and your share of arbitrator compensation
            shall be governed by the AAA Consumer Rules and, where appropriate,
            limited by the AAA Consumer Rules.{" "}
            <bdt className="block-component" />
            The arbitration may be conducted in person, through the submission
            of documents, by phone, or online. The arbitrator will make a
            decision in writing, but need not provide a statement of reasons
            unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in{" "}
            <bdt className="block-component" />
            <bdt className="question">United States</bdt>,
            <bdt className="statement-end-if-in-editor" />
            <bdt className="block-component" />
            <bdt className="question">California</bdt>
            <bdt className="statement-end-if-in-editor" />. Except as otherwise
            provided herein, the Parties may litigate in court to compel
            arbitration, stay proceedings pending arbitration, or to confirm,
            modify, vacate, or enter judgment on the award entered by the
            arbitrator.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
          </span>
          <bdt className="block-component" style={{ fontSize: 15 }} />
          <span style={{ fontSize: 15 }}>&nbsp;state and federal courts</span>
          <bdt
            className="statement-end-if-in-editor"
            style={{ fontSize: 15 }}
          />
          <span style={{ fontSize: 15 }}>&nbsp;located in</span>
          <bdt className="block-component" style={{ fontSize: 15 }} />
          <bdt className="question">United States</bdt>
          <span style={{ fontSize: 15 }}>,</span>
          <bdt
            className="statement-end-if-in-editor"
            style={{ fontSize: 15 }}
          />
          <bdt className="block-component" style={{ fontSize: 15 }} />
          <bdt className="question" style={{ fontSize: 15 }}>
            California
          </bdt>
          <bdt
            className="statement-end-if-in-editor"
            style={{ fontSize: 15 }}
          />
          <span style={{ fontSize: 15 }}>
            , and the Parties hereby consent to, and waive all defenses of lack
            of personal jurisdiction, and forum non conveniens with respect to
            venue and jurisdiction in such
            <bdt className="block-component" /> state and federal courts
            <bdt className="statement-end-if-in-editor" />. Application of the
            United Nations Convention on Contracts for the International Sale of
            Goods and the Uniform Computer Information Transaction Act (UCITA)
            are excluded from these Terms of Use.
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            <bdt className="block-component" />
            In no event shall any Dispute brought by either Party related in any
            way to the Site be commenced more than{" "}
            <bdt className="question">one (1)</bdt> years after the cause of
            action arose. <bdt className="statement-end-if-in-editor" />
            If this provision is found to be illegal or unenforceable, then
            neither Party will elect to arbitrate any Dispute falling within
            that portion of this provision found to be illegal or unenforceable
            and such Dispute shall be decided by a court of competent
            jurisdiction within the courts listed for jurisdiction above, and
            the Parties agree to submit to the personal jurisdiction of that
            court.
            <bdt className="block-component" />
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>Restrictions</strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_2"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
          <strong>Exceptions to Informal Negotiations and Arbitration</strong>
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations binding arbitration:
          (a) any Disputes seeking to enforce or protect, or concerning the
          validity of, any of the intellectual property rights of a Party; (b)
          any Dispute related to, or arising from, allegations of theft, piracy,
          invasion of privacy, or unauthorized use; and (c) any claim for
          injunctive relief. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="statement-end-if-in-editor">
            <bdt className="statement-end-if-in-editor" />
          </bdt>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="corrections"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ fontSize: 19 }}>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          18.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              CORRECTIONS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions that may relate to the Marketplace
          Offerings, including descriptions, pricing, availability, and various
          other information. We reserve the right to correct any errors,
          inaccuracies, or omissions and to change or update the information on
          the Site at any time, without prior notice.
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="disclaimer"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 19, color: "rgb(0, 0, 0)" }}>
            <strong>
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          19.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              DISCLAIMER
            </strong>
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
            FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
            OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
            INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="liability"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          20.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              LIMITATIONS OF LIABILITY
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            <span data-custom-class="body_text">
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </span>
            <bdt
              className="block-container if"
              data-type="if"
              id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
            >
              <span data-custom-class="body_text">
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="limitations_liability_option"
                    data-type="statement"
                  />
                  <bdt data-type="body">
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                    OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                    OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO{" "}
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="limitations_liability_option"
                          data-type="statement"
                        />
                        <bdt data-type="body">
                          THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US
                          <bdt data-type="conditional-block">
                            <bdt
                              className="block-component"
                              data-record-question-key="limilation_liability_time_option"
                              data-type="statement"
                            />{" "}
                            OR{" "}
                            <bdt
                              className="block-container question question-in-editor"
                              data-id="243ad246-9e92-b24d-beee-940be6aa7854"
                              data-type="question"
                            >
                              $5.00 USD
                            </bdt>
                          </bdt>
                        </bdt>
                      </bdt>
                      <span
                        style={{
                          fontSize: "11.0pt",
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          color: "#595959",
                          msoThemecolor: "text1",
                          msoThemetint: 166
                        }}
                      >
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                        >
                          <bdt
                            className="statement-end-if-in-editor"
                            data-type="close"
                          >
                            <span data-custom-class="body_text">.&nbsp;</span>
                          </bdt>
                        </bdt>
                      </span>
                      <span data-custom-class="body_text">
                        CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
                        ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
                        OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                        YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
                        MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                        RIGHTS.
                      </span>
                    </bdt>
                  </bdt>
                </bdt>
              </span>
              <bdt className="statement-end-if-in-editor" data-type="close">
                <span data-custom-class="body_text" />
              </bdt>
            </bdt>
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="indemnification"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          21.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              INDEMNIFICATION
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of:{" "}
            <bdt
              className="block-container if"
              data-type="if"
              id="475fffa5-05ca-def8-ac88-f426b238903c"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_post_content_option"
                  data-type="statement"
                />
                <bdt data-type="body">(1) your Contributions;&nbsp;</bdt>
              </bdt>
              <bdt className="statement-end-if-in-editor" data-type="close" />
            </bdt>
            (<span style={{ fontSize: "14.6667px" }}>2</span>) use of the Site;
            (<span style={{ fontSize: "14.6667px" }}>3</span>) breach of these
            Terms of Use; (<span style={{ fontSize: "14.6667px" }}>4</span>) any
            breach of your representations and warranties set forth in these
            Terms of Use; (<span style={{ fontSize: "14.6667px" }}>5</span>)
            your violation of the rights of a third party, including but not
            limited to intellectual property rights; or (
            <span style={{ fontSize: "14.6667px" }}>6</span>) any overt harmful
            act toward any other user of the Site with whom you connected via
            the Site. Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="userdata"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          22.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              USER DATA
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="electronic"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          23.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SITE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="block-component" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="california"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          24.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              CALIFORNIA USERS AND RESIDENTS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <bdt className="statement-end-if-in-editor" />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="misc"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{ lineHeight: "115%", fontFamily: "Arial", fontSize: 19 }}
            >
              <strong>
                <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: 19
                      }}
                    >
                      <strong>
                        <span
                          style={{
                            lineHeight: "115%",
                            fontFamily: "Arial",
                            fontSize: 19
                          }}
                        >
                          25.
                        </span>
                      </strong>
                    </span>
                  </strong>
                </span>
                &nbsp;
              </strong>
              MISCELLANEOUS
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            These Terms of Use and any policies or operating rules posted by us
            on the Site or in respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.
            <bdt className="block-component" />
          </span>
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="heading_1"
          id="contact"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
              <span style={{ fontSize: 19 }}>
                <strong>
                  <span style={{ lineHeight: "24.5333px", fontSize: 19 }}>
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: 19
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              fontSize: 19
                            }}
                          >
                            26.
                          </span>
                        </strong>
                      </span>
                    </strong>
                  </span>
                  &nbsp;
                </strong>
                CONTACT US
              </span>
            </span>
          </strong>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span
            style={{
              fontSize: "11.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              color: "#595959",
              msoThemecolor: "text1",
              msoThemetint: 166
            }}
          >
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us at:
          </span>
        </div>
        <div className="MsoNormal" style={{ lineHeight: 1, textAlign: "left" }}>
          <br />
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <bdt className="question" />
              <strong>
                <bdt className="block-component" />
              </strong>
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt className="question">
                <strong />
              </bdt>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "rgb(89, 89, 89)"
                }}
              >
                <bdt className="statement-end-if-in-editor" />
              </span>
              <bdt className="block-component" />
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            <span
              style={{
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "rgb(89, 89, 89)"
              }}
            >
              <bdt className="question" />
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "rgb(89, 89, 89)"
                }}
              >
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "rgb(89, 89, 89)"
                  }}
                >
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <span style={{ fontSize: 15 }}>
            <bdt className="question" />
            <strong>
              <bdt className="statement-end-if-in-editor">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span style={{ fontSize: 15 }}>
                      <bdt className="statement-end-if-in-editor">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <strong>
                            <span style={{ fontSize: 15 }}>
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                              </bdt>
                            </span>
                          </strong>
                          <bdt className="statement-end-if-in-editor">
                            <strong />
                          </bdt>
                        </span>
                      </bdt>
                    </span>
                  </strong>
                </span>
              </bdt>
            </strong>
          </span>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <strong>
              <strong>
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166
                  }}
                />
              </strong>
            </strong>
          </strong>
        </div>
        <div
          className="MsoNormal"
          data-custom-class="body_text"
          style={{ lineHeight: "1.5", textAlign: "left" }}
        >
          <strong>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166
              }}
            >
              <strong>
                <bdt className="question">contact@theilker.com</bdt>
              </strong>
            </span>
          </strong>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n                ul {\n                    list-style-type: square;\n                }\n\n                ul>li>ul {\n                    list-style-type: circle;\n                }\n\n                ul>li>ul>li>ul {\n                    list-style-type: square;\n                }\n\n                ol li {\n                    font-family: Arial;\n                }\n            "
        }}
      />
    </div>
  </div>
  <noscript>Your browser does not support JavaScript!</noscript>
  <div style={{ clear: "both", display: "block", height: 0 }} />
</>
} />

        {/* Catch-all route for 404 - Not Found */}
        <Route path="*" element={
          <>
          <center style={{fontSize: 50}}>
            <Link to={'/privacy-policy'}>Privacy Policy</Link><br></br>
            <Link to={'/terms-of-service'}>Terms of Service</Link>
          </center>
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
